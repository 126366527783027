const layoutTypes = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  TWOCOLUMN: 'twocolumn',
};

export type LayoutTypes = typeof layoutTypes[keyof typeof layoutTypes]

const layoutModeTypes = {
  LIGHTMODE: 'light',
  DARKMODE: 'dark',
};

export type LayoutModes = typeof layoutModeTypes[keyof typeof layoutModeTypes]

const leftSidebarTypes = {
  LIGHT: 'light',
  DARK: 'dark',
};

export type LeftSidebarTypes = typeof leftSidebarTypes[keyof typeof leftSidebarTypes]

const layoutWidthTypes = {
  FLUID: 'lg',
  BOXED: 'boxed',
};

export type LayoutWidthTypes = typeof layoutWidthTypes[keyof typeof layoutWidthTypes]

const layoutPositionTypes = {
  FIXED: 'fixed',
  SCROLLABLE: 'scrollable',
};

export type LayoutPositionTypes = typeof layoutPositionTypes[keyof typeof layoutPositionTypes]

const topbarThemeTypes = {
  LIGHT: 'light',
  DARK: 'dark',
};

export type TopbarThemeTypes = typeof topbarThemeTypes[keyof typeof topbarThemeTypes]

const leftsidbarSizeTypes = {
  DEFAULT: 'lg',
  COMPACT: 'md',
  SMALLICON: 'sm',
  SMALLHOVER: 'sm-hover',
};

export type LeftSidebarSizeTypes = typeof leftsidbarSizeTypes[keyof typeof leftsidbarSizeTypes]

const leftSidebarViewTypes = {
  DEFAULT: 'default',
  DETACHED: 'detached',
}

export type LeftSidebarViewTypes = typeof leftSidebarViewTypes[keyof typeof leftSidebarViewTypes]

export {
  layoutTypes,
  layoutModeTypes,
  leftSidebarTypes,
  layoutWidthTypes,
  layoutPositionTypes,
  topbarThemeTypes,
  leftsidbarSizeTypes,
  leftSidebarViewTypes,
};
