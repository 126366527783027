import API from '../../api/API'
import * as actions from './actions'

export type IAuthState = {
  isLogining: boolean
  token?: string
  userName?: string
  userId?: string
  roles: string[]
}

export const initialState: IAuthState = {
  isLogining: false,
  token: undefined,
  userName: API.getUserName(),
  userId: API.getUserId(),
  roles: API.getUserRoles(),
}

export const authReducer = (state: IAuthState = initialState, action: actions.IAuthActions) => {
  switch (action.type) {
    case actions.REQUEST_LOGIN:
      return {
        ...state,
        isLogining: true,
      }
    case actions.REQUEST_LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        isLogining: false,
        userName: action.payload.username,
        roles: API.getUserRoles(),
      }
    case actions.CHECK_AUTH:
      return {
        ...state,
        token: API.getAuthToken(),
        userName: API.getUserName(),
        userId: API.getUserId(),
        roles: API.getUserRoles(),
      }
    default:
      return state
  }
}
