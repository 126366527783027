import { IPagination } from 'interface/retailer/common'
import { IStoreAddress } from 'interface/retailer/store'

export interface IStore {
  storeId: string
  storeCode: string
  storeName: string
  status: number
}

export interface IRetailerStores {
  stores: IStoreCloud[]
  pagination?: IPagination
}

export interface IStoreCloud {
  id: string
  name: string
  address: IStoreAddress
  status: StoreStatus
}
export enum StoreStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  CLOSED_FOR_MAINTENANCE = 'CLOSED_FOR_MAINTENANCE',
}
