import React, { FC } from 'react'
import { Button, ButtonContainer, Confirm } from '../style'

interface IRefundControlsProps {
  onCancel: () => void
  onSubmit: () => void
  originalTotalPrice: number
  refundAmount: number
}

export const RefundControls: FC<IRefundControlsProps> = ({
  onCancel, onSubmit, refundAmount, originalTotalPrice,
}) => (
  <ButtonContainer>
    <Button onClick={onCancel}>Cancel</Button>
    <Confirm onClick={onSubmit} disabled={refundAmount > originalTotalPrice} disabledBtn={refundAmount > originalTotalPrice}>
      Submit
    </Confirm>
  </ButtonContainer>
)
