import { OrderStatus } from 'stores/orders/types'

export const ORDER_STATUS_TO_LABEL: Record<OrderStatus, string> = {
  [OrderStatus.DRAFT]: 'Draft',
  [OrderStatus.PAID]: 'Paid',
  [OrderStatus.UNPAID]: 'Unpaid',
  [OrderStatus.CONTESTED]: 'Pending',
  [OrderStatus.REVIEWED]: 'Reviewed',
  [OrderStatus.PAYMENT_PROCESSING]: 'Payment Processing',
  [OrderStatus.UNKNOWN]: 'Unknown status',
}

export const ORDER_STATUS_TO_COLOR: Record<OrderStatus, {color: string, bgColor: string}> = {
  [OrderStatus.DRAFT]: {
    color: '#ECB85D',
    bgColor: '#FEF8EF',
  },
  [OrderStatus.PAID]: {
    color: '#0AB39C',
    bgColor: '#E6F7F5',
  },
  [OrderStatus.UNPAID]: {
    color: '#5F6976',
    bgColor: '#F4F6F7',
  },
  [OrderStatus.CONTESTED]: {
    color: '#F48A9B',
    bgColor: '#FEF4F5',
  },
  [OrderStatus.REVIEWED]: {
    color: '#40B4E5',
    bgColor: '#ECF8FD',
  },
  [OrderStatus.PAYMENT_PROCESSING]: {
    color: '#b940e5',
    bgColor: '#f7ecfd',
  },
  [OrderStatus.UNKNOWN]: {
    color: '#5F6976',
    bgColor: '#F4F6F7',
  },
}
