import { IPagination } from 'interface/retailer/common'
import { CreateOrderContestPayload, IOrder, IProductSnapshot } from 'stores/orders/types'
import { IOrderContest } from 'stores/receipt/types'
import { OrderStatus } from '../stores/orders/types'
import API from './API'
import { IResponse, IResponseBase } from '../interface/response/success.response'
import { toUrl } from './helper'

export type QueryOrderList = {
  id?: number
  startTime?: Date
  endTime?: Date
  page?: number
  status?: OrderStatus
  storeId?: string
  pageSize?: number
}
export default class OrderAPI {
  static getOrderList(req: QueryOrderList): Promise<IResponse<{ data: IOrder[], pagination: IPagination }>> {
    const startTime = req.startTime?.toISOString()
    const endTime = req.endTime?.toISOString()
    return API.get(toUrl('/api/admin/v2/orders', { ...req, startTime, endTime }))
  }

  static async getOrder(orderId: string) {
    return {
      msg: '',
      code: 0,
      metadata: {},
      data: (await API.get(`/api/admin/v2/orders/${orderId}`)) as unknown as IOrder,
    }
  }

  static getCases(sessionId: string): Promise<IResponseBase> {
    return API.get(`/sessions/${sessionId}`)
  }

  static getRetailerProducts(
    name?: string | [],
    time?: number | string,
    page?: number,
    pageSize?: number,
  ): Promise<IResponse<IProductSnapshot[]>> {
    return API.get(
      toUrl('/api/admin/v2/products/snapshots', {
        name,
        time,
        page: page ? page - 1 : 0,
        pageSize: pageSize || 100,
      }),
    )
  }

  static getOrderContest(orderId: number): Promise<IOrderContest> {
    return API.get(`/api/admin/v2/contests/${orderId}`)
  }

  static createOrderContest(contest: CreateOrderContestPayload): Promise<IOrderContest> {
    return API.post('/api/admin/v2/contests', contest)
  }

  static submitContestedOrder(
    orderId: number,
    items: { productId: number, reviewedQuantity: number }[],
    reviewedTotalPrice?: number,
  ): Promise<IOrderContest> {
    return API.patch(`/api/admin/v2/contests/${orderId}`, { items, ...(reviewedTotalPrice && { reviewedTotalPrice }) })
  }
}
