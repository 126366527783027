import React from 'react'
import * as R from 'ramda'
import { TotalContainer, ContainerItem } from '../style'

interface IProps {
  title: string
  quantity?: number
  currencySymbol: string
  price: number
  darkFont?: boolean
  lineThrough?: boolean
  border?: boolean
}

export const ReceiptAmount: React.FC<IProps> = ({
  title, quantity, currencySymbol, price, darkFont, lineThrough, border,
}) => (
  <TotalContainer border={border} darkFont={darkFont}>
    <ContainerItem>
      {title}
    </ContainerItem>
    {!R.isNil(quantity) && (
      <ContainerItem align="center" lineThrough={lineThrough}>
        {`${quantity} ${quantity !== 1 ? 'Items' : 'Item'}`}
      </ContainerItem>
    )}
    <ContainerItem align="right" lineThrough={lineThrough}>
      {currencySymbol}
      {price.toFixed(2)}
    </ContainerItem>
  </TotalContainer>
)
