import React, { FC } from 'react'

interface IProps {
    strokeColor?: string
}

export const StopIcon: FC<IProps> = ({ strokeColor }) => (
  <svg
    width={21}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke={strokeColor}
      strokeWidth={1.75}
      d="M3.619 3.881l14.142 14.142"
    />
    <circle cx={10.5} cy={11} r={9.625} stroke={strokeColor} strokeWidth={1.75} />
  </svg>
)
