/* eslint-disable max-len */
import React, { FC } from 'react'

interface IProps {
  fillColor?: string
}

export const EditIcon: FC<IProps> = ({ fillColor }) => (
  <svg
    width={17}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.679 1.75l-.81-.802a1.614 1.614 0 00-2.232 0l-1.078 1.04-6.187 6.21a.454.454 0 00-.126.253l-.38 2.818a.424.424 0 00.127.38.416.416 0 00.312.133h.067l2.819-.402a.453.453 0 00.253-.126l6.186-6.239 1.049-1.04a1.599 1.599 0 000-2.232v.008zm-7.786 8.76l-2.082.298.298-2.082L11.9 2.933l1.785 1.792-5.793 5.785zm7.154-7.146l-.744.744-1.777-1.8.744-.743a.744.744 0 01.974 0l.81.81a.671.671 0 01.201.491.678.678 0 01-.208.498zm.23 4.997v3.718a3.42 3.42 0 01-3.42 3.421H4.42A3.42 3.42 0 011 12.08V4.642a3.42 3.42 0 013.42-3.42H8.14a.446.446 0 010 .892H4.42a2.528 2.528 0 00-2.529 2.528v7.436a2.529 2.529 0 002.529 2.529h7.436a2.528 2.528 0 002.528-2.529V8.361a.447.447 0 01.892 0z"
      fill={fillColor}
      stroke={fillColor}
      strokeWidth={0.3}
    />
  </svg>
)
