import styled from 'styled-components'

export const Container = styled.div`
`
export const PlayerWrap = styled(Container)`
  position: relative;
  flex: 1;
  min-width: 458px;
  margin-right: 24px;
  padding: 0;
  background-color: #2e415b;
`
export const RightPanel = styled(Container)`
  flex: 1;
  min-width: 458px;
`
export const GeneralInfo = styled(Container)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 36px;
  height: 100%;
  padding: 16px 24px;

  overflow-y: auto;
`

export const SubTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 11px;
  align-items: center;
`

export const SubTitle = styled.span`
  display: flex;
  gap: 5px;
  align-items: center;
  color: #2e415b;
  font-weight: 500;
  font-size: 20px;
`

export const Flex = styled.div`
  display: flex;
  height: calc(100%);
`
