import { TableRow } from 'components/orderList/style'
import styled from 'styled-components'

type RowProp = {
  isLast?: boolean
}

export const CaseRow = styled(TableRow)<RowProp>`
  display: flex;
  box-sizing: border-box;
  min-height: 44px;
  padding: 12px 15px;
  border-top: 1px solid #d8e6ed;
  border-bottom: none;
`

export const ActionCount = styled.span`
  margin-right: 5px;
  color: #53bce8;
`
