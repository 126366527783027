import styled from 'styled-components'
import { theme } from 'styled-tools'

export const DropdownWrapper = styled.div`
  position: fixed;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: max-content;
  text-align: left;
  background: ${theme('colors.grey.25')};
  border: 1px solid ${theme('colors.grey.300')};
  border-radius: 18px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
`
