
import React, { Fragment, useEffect } from 'react'
import {
  useTable,
  usePagination,
  Column,
  UseTableRowProps,
  UseTableCellProps,
  HeaderGroup,
  TableState,
} from 'react-table'
import { Table, Row, Col, Button } from 'reactstrap'

interface TableProps<T> {
  columns: Column[]
  data: T[]
  currPage?: number
  customPageSize?: number
  pageCount?: number
  tableClass?: string
  theadClass?: string
  thClass?: string
  divClass?: string
  onRowClick?: (row: T) => void
  pagination?: null
  fetchData?: (pageIndex: number, pageSize: number) => void
}

const TableContainer = <T extends object>({
  columns,
  data,
  currPage = 0,
  customPageSize = 15,
  pageCount = 1,
  tableClass,
  theadClass,
  thClass,
  divClass,
  pagination,
  onRowClick,
  fetchData,
}: TableProps<T>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex: statePageIndex, pageSize },
    gotoPage,
  } = useTable(
    ({
      columns: columns as Column<T>[],
      data,
      initialState: { pageIndex: currPage, pageSize: customPageSize } as TableState,
      manualPagination: true,
      pageCount,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any),
    usePagination,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any

  useEffect(() => {
    gotoPage(currPage)
  }, [currPage])

  const pageIndex = Number(statePageIndex)

  return (
    <>
      <div className={divClass}>
        <Table hover {...getTableProps()} className={tableClass}>
          <thead className={theadClass}>
            {headerGroups.map((headerGroup: HeaderGroup<T>) => {
              const headerGroupProps = headerGroup.getHeaderGroupProps()
              return (
                <tr {...headerGroupProps} key={headerGroupProps.key} >
                  {headerGroup.headers.map((column: HeaderGroup<T>) => (
                    <th
                      key={column.id}
                      className={thClass}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              )
            })}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row: UseTableRowProps<T>) => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr onClick={() => onRowClick && onRowClick(row.original)}>
                    {row.cells.map((cell: UseTableCellProps<T>, index) => (
                      <td {...cell.getCellProps()} key={index}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>
      {pagination !== null && (
        <Row className="justify-content-md-end justify-content-center align-items-center pe-2">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                outline
                onClick={() => {
                  previousPage()
                  fetchData && fetchData(pageIndex - 1, pageSize)
                }}
                disabled={!canPreviousPage}
              >
                Previous
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page
            {' '}
            <strong>
              {pageIndex + 1}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                outline
                onClick={() => {
                  nextPage()
                  fetchData && fetchData(pageIndex + 1, pageSize)
                }}
                disabled={!canNextPage}
              >
                Next
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </>
  )
}

export default TableContainer
