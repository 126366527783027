import styled from 'styled-components'
import { theme } from 'styled-tools'
import { ITextColors } from 'theme/colors'

interface IProps {
  color: keyof ITextColors
}

export const Header = styled.h1`
  color: ${(props: IProps) => theme(`textColors.${props.color}`)};
`
