import { FlexRow } from 'components/common/layout'
import styled from 'styled-components'

export const InputContainer = styled.div`
  position: absolute;
  right: -50%;
  margin-top: 10px;
`
export const DateBox = styled.div`
  position: relative;
  width: 100%;
  width: -webkit-fit-content;
  height: auto;
  padding: 15px;
  background: #fefefe;
  border: 1px solid #d8e6ed;
  border-radius: 25px;
  box-shadow: 4px 3px 5px 1px rgba(33, 43, 54, 0.2);
  -webkit-animation-name: jgQpwH;
  -webkit-animation-timing-function: ease-in;
`

export const RowCenter = styled(FlexRow)`
  justify-content: space-between;
`

export const ResetIcon = styled.img`
  margin-right: 2px;
  margin-left: 4px;
`

export const TagName = styled.span`
  color: #212b36;
  font-size: 14px;
  text-align: left;
`

export const DateInput = styled.input`
  box-sizing: border-box;
  width: 270px;
  height: 36px;
  margin-top: 7px;
  padding: 8px 12px;
  color: #212b36;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  background: #fff;
  border: none;
  border-radius: 10px;
  outline: none;
  box-shadow: inset 0px 0px 0px 1px rgba(102, 113, 123, 0.25), inset 0px 1px 2px rgba(102, 113, 123, 0.21);
`

export const DateFooter = styled(FlexRow)`
  justify-content: flex-end;
  width: 100%;
  height: auto;
`

export const Button = styled.button`
  width: 82px;
  height: 40px;
  margin-left: 13px;
  color: #2e5984;
  background: transparent;
  border: none;
  cursor: pointer;
  &:hover {
    color: #1c3e60;
  }
`

export const Cancel = styled(Button)`
  box-sizing: border-box;
  color: #212b36;
  background: #fff;
  background: linear-gradient(180deg, #fff 0%, #f9fafb 100%);
  border: 1px solid #c4cdd5;
  border-radius: 5px;
  border-radius: 3px;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);

  &:hover {
    border: 2px solid #d3d9de;
  }
`

export const Apply = styled(Button)`
  color: #fff;
  background: #40b4e5;
  border-radius: 3px;

  &:hover {
    color: #40b4e5;
    background: white;
    border: 2px solid #40b4e5;
  }
`
