import { call, put, takeEvery } from 'redux-saga/effects'
import {
  REQUEST_MENU, requestMenuSuccess, requestStoresSuccess, REQUEST_STORES,
} from './actions'
import { IMenu } from '../../interface/admin/menu'
import AdminAPI from '../../api/admin'
import { IRetailerStores, IStoreCloud, StoreStatus } from '../../interface/admin/store'

export function* sagaRequestMenu() {
  const response: IMenu[] = yield call(AdminAPI.menus)
  yield put(requestMenuSuccess(response))
}

export function* sagaRequestStores() {
  const response: IRetailerStores = yield call(AdminAPI.stores)

  const stores = response.stores.map((store: IStoreCloud) => ({
    storeId: store.id,
    storeCode: store.id,
    storeName: store.name,
    status: store.status === StoreStatus.OPEN ? 1 : 0,
  }))
  yield put(requestStoresSuccess(stores))
}

export function* adminSagas() {
  yield takeEvery(REQUEST_MENU, sagaRequestMenu)
  yield takeEvery(REQUEST_STORES, sagaRequestStores)
}
