import React, { FC, useMemo } from 'react'
import { IOrderContest } from 'stores/receipt/types'
import { IOrder } from 'stores/orders/types'
import { SubTitle } from 'components/orderDetail/style'
import { StopIcon } from 'components/icons/stop'
import { theme } from 'theme'
import { ProductSelector } from '../productSelector'
import {
  Container,
  Title,
  ContestView,
  Divider,
  FeedbackView,
  Message,
  ProductList,
  ProductPlaceholder,
  PlaceholderMessage,
} from './style'
import { Container as ProductContainer } from '../productSelector/style'

interface IProps {
  data?: IOrderContest
  currentOrder: IOrder
  finalOrder: IOrder
}

export const ContestProduct: FC<IProps> = ({ data, currentOrder, finalOrder }) => {
  const contestedProducts = useMemo(() => data?.items.filter((p) => p.contestedQuantity !== p.originalQuantity) ?? [], [data])
  return (
    <Container>
      <SubTitle>Contested Products</SubTitle>
      <ContestView>
        <ProductList>
          {contestedProducts.length > 0 ? (
            contestedProducts.map((p) => (
              <ProductSelector key={p.id} product={p} currentOrder={currentOrder} finalOrder={finalOrder} />
            ))
          ) : (
            <ProductContainer>
              <ProductPlaceholder>
                <StopIcon strokeColor={theme.colors.grey[600]} />
                <PlaceholderMessage>No registered contested products</PlaceholderMessage>
              </ProductPlaceholder>
            </ProductContainer>
          )}
        </ProductList>
        <Divider />
        <FeedbackView>
          <Title>Customer Message</Title>
          <Message>
            {data?.message || '-'}
          </Message>
        </FeedbackView>
      </ContestView>
    </Container>
  )
}
