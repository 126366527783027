export const config = {
  api: {
    url: process.env.REACT_APP_HOST_URL,
  },
  hat: {
    url: process.env.REACT_APP_HAT_BASE_URL || 'https://next.hat.eu.aifi.io',
  },
  cookie: {
    // eslint-disable-next-line no-eval
    expires: eval(process.env.REACT_APP_HAT_ADMIN_EXPIRES_TIME || '') || 10 * 60 * 60,
  },
  misc: {
    showTax: process.env.REACT_APP_ORDER_SHOW_TAX ?? false,
  },
}
