import React, { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

// import Components

import { IAppState } from 'stores'
import Sidebar from './Sidebar'
// import Footer from './Footer';
// import RightSidebar from '../../VelzonComponents/Common/RightSidebar';

// import actions
import {
  changeLayout,
  changeSidebarTheme,
  changeLayoutMode,
  changeLayoutWidth,
  changeLayoutPosition,
  changeTopbarTheme,
  changeLeftsidebarSizeType,
  changeLeftsidebarViewType,
} from '../../store/actions'

interface LayoutProps extends RouteComponentProps {
  children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
}

const Layout: React.FC<LayoutProps> = (props) => {
  const dispatch = useDispatch()
  const {
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
  } = useSelector((state: IAppState) => ({
    layoutType: state.Layout.layoutType,
    leftSidebarType: state.Layout.leftSidebarType,
    layoutModeType: state.Layout.layoutModeType,
    layoutWidthType: state.Layout.layoutWidthType,
    layoutPositionType: state.Layout.layoutPositionType,
    topbarThemeType: state.Layout.topbarThemeType,
    leftsidbarSizeType: state.Layout.leftsidbarSizeType,
    leftSidebarViewType: state.Layout.leftSidebarViewType,
  }))
  /*
    layout settings
    */
  useEffect(() => {
    if (
      layoutType
      || leftSidebarType
      || layoutModeType
      || layoutWidthType
      || layoutPositionType
      || topbarThemeType
      || leftsidbarSizeType
      || leftSidebarViewType
    ) {
      dispatch(changeLeftsidebarViewType(leftSidebarViewType))
      dispatch(changeLeftsidebarSizeType(leftsidbarSizeType))
      dispatch(changeSidebarTheme(leftSidebarType))
      dispatch(changeLayoutMode(layoutModeType))
      dispatch(changeLayoutWidth(layoutWidthType))
      dispatch(changeLayoutPosition(layoutPositionType))
      dispatch(changeTopbarTheme(topbarThemeType))
      dispatch(changeLayout(layoutType))
    }
  }, [
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    dispatch,
  ])
  /*
    call dark/light mode
    */
  // const onChangeLayoutMode = (value: LayoutTypes) => {
  //   if (changeLayoutMode) {
  //     dispatch(changeLayoutMode(value))
  //   }
  // }

  return (
    <>
      <div id="layout-wrapper">
        {/* <Header
          headerClass={headerClass}
          layoutModeType={layoutModeType}
          onChangeLayoutMode={onChangeLayoutMode}
        /> */}
        <Sidebar layoutType={layoutType} />
        <div className="main-content">
          {props.children}
        </div>
      </div>
      {/* Theme Customizer */}
      {/* <RightSidebar /> */}
    </>
  )
}

export default withRouter(Layout)
