import styled from 'styled-components'
import Addition from 'assets/icons/addition.svg'
import Subtraction from 'assets/icons/subtraction.svg'
import is from 'styled-is'
import { switchProp } from 'styled-tools'
import { theme } from 'theme'
import { LABELS } from './types'

export const Container = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  color: #6b737d;
  font: normal 500 14px/30px Roboto;
  background: #fff;
  border: 1px solid #dce8ef;
  border-radius: 10px;

  &:hover {
    border: 1px solid #afbbc2;
  }
`
export const Thumbnail = styled.div<{ thumbnail: string }>`
  width: 60px;
  height: 60px;
  background: url('${(props: { thumbnail: string }) => props.thumbnail}') #fff no-repeat center center/contain;
  border-radius: 10px 0 0 10px;
`
export const Quantity = styled.input`
  box-sizing: border-box;
  width: 30px;
  height: 32px;
  text-align: center;
  background: #fff;
  border: 1px solid #dce8ef;
  border-radius: 5px;
`
export const Add = styled.button`
  width: 32px;
  height: 32px;
  background: #fff;
  background: url('${Addition}') #fff no-repeat center center/8px 8px;
  border: none;
  cursor: pointer;
`
export const Sub = styled(Add)`
  background: url('${Subtraction}') #fff no-repeat center center/8px 8px;
`
export const UnitPrice = styled.div`
  color: #c5c7cd;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
`
export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 220px;
  color: #6b737d;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: left;
`

export const ItemInfo = styled(Info)`
  width: 50px;
  text-align: left;
`

export const Name = styled.div`
  display: -webkit-box;
  flex: 1;
  overflow: hidden;
  color: #6b737d;
  font-weight: 500;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.2px;
`

export const Tag = styled.span`
  width: 30px;
  height: 25px;
  color: ${(props: { color: string }) => props.color};
  font-size: 10px;
  line-height: 25px;
  text-align: center;
  background: ${(props: { background: string }) => props.background};
  border-radius: 6px;
`
export const PriceWrap = styled.div`
  width: min-content;
  line-height: 18px;
  text-align: right;
`

export const CurrentItem = styled.div`
  color: #6b737d;
  font-weight: 500;
  font-size: 14px;
`
export const OldItem = styled.div`
  color: #9ba9b1;
  font-weight: 500;
  font-size: 14px;
  text-decoration-line: line-through;
`

export const CloseIcon = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 18px;
  height: 18px;
  padding: 3px;
  background: #fdd6e0;
  border-radius: 100%;
  cursor: pointer;
`

export const CheckBoxWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const CheckBox = styled.div<{
  selected?: boolean
  isContested?: boolean
}>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6b737d;
  height: 28px;
  width: 28px;
  border: 2px solid #dce8ef;
  border-radius: 56px;
  text-align: center;
  font-weight: bold;
  ${is('selected')`
    background: #dfefdc;
    border-color: #dfefdc;
  `}
  ${is('isContested')`
    &:hover {
      background: #dfefdc;
      border-color: #dfefdc;
      cursor: pointer;
    }
  `}
`

export const CheckBoxImg = styled.img`
  color: black;
  ${is('selected')`
  `}
`

export const Label = styled.div<{ label: LABELS }>`
  ${switchProp('label', {
    [LABELS.REMOVED]: `
        color: #9097A1;
        background-color: #D5DCE6;
    `,
    [LABELS.ADDED]: `
        color: #8498B7;
        background-color: #D6E4FB;
    `,
    [LABELS.QUANTITY]: `
        color: #F48A9B;
        background-color: #FFE6EA;
    `,
    [LABELS.NO_CHANGE]: `
        color: #9097A1;
        background-color: #DDDDDD;
    `,
  })}
  height: 20px;
  border-radius: 20px;
  font-size: 12px;
  padding: 0 10px;
  font-weight: ${theme.fonts.weights.bold};
  margin-right: 4px;
  margin-bottom: 2px;
`

export const IssueLabel = styled(Label) <{
  required?: boolean
}>`
  width: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${theme.colors.deepPurple};
  background-color: ${theme.colors.lightPurple};
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.purple};
  }
  ${is('required')`
    color:  ${theme.colors.red};
    border: 1px solid ${theme.colors.red};
  `}
`

export const IssueText = styled.span<{margin: boolean}>`
  ${is('margin')`
    margin-right: 10px;
  `} 
`