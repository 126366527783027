import styled from 'styled-components'

// Icon
import SearchIcon from 'assets/icons/search-icon.svg'

export const Container = styled.div`
  flex: 1;
`
export const SearchInput = styled.input`
  box-sizing: border-box;
  width: 262px;
  height: 36px;
  padding-left: 45px;
  color: #9ba9b1;
  font: normal normal 13px/13px Roboto;
  line-height: 36px;
  background: url(${SearchIcon}) #fff no-repeat 12px center/15px 15px;
  border: 1px solid #CFDDE4;
border-radius: 4px;
  outline: none;
`
