/* eslint-disable max-len */
import React, { FC } from 'react'

interface IProp{
  title?: string
  tip?: string
  onClick?: () => void
}
export const CopyIcon: FC<IProp> = ({ title, tip, onClick }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    }}
  >
    {title}
    <span style={{ width: 5 }}></span>
    <svg
      data-tip={tip}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
    >
      <path d="M85.333333 170.666667a85.333333 85.333333 0 0 1 85.333334-85.333334h426.666666a85.333333 85.333333 0 0 1 85.333334 85.333334v170.666666h170.666666a85.333333 85.333333 0 0 1 85.333334 85.333334v426.666666a85.333333 85.333333 0 0 1-85.333334 85.333334H426.666667a85.333333 85.333333 0 0 1-85.333334-85.333334v-170.666666H170.666667a85.333333 85.333333 0 0 1-85.333334-85.333334V170.666667z m341.333334 512v170.666666h426.666666V426.666667h-170.666666v170.666666a85.333333 85.333333 0 0 1-85.333334 85.333334h-170.666666z m170.666666-85.333334V170.666667H170.666667v426.666666h426.666666z" fill="#0D0D0D" p-id="2572"></path>
    </svg>
  </div>
)
