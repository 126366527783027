import API from './API'
import { IMenu } from '../interface/admin/menu'
import { IRetailerStores } from '../interface/admin/store'

export default class AdminAPI {
  static menus(): Promise<IMenu[]> {
    return API.get('/admin/menus')
  }

  static stores(): Promise<IRetailerStores> {
    return API.get('/api/admin/v2/stores?count=200')
  }
}
