import { AuditCategory } from 'interface/audits/audit'
import { IPagination } from 'interface/retailer/common'
import { IContest } from 'stores/contest/types'
import { ICase, IOrderContest } from '../receipt/types'

export interface IOrder {
  createdAt: string
  customerId: string
  sessionId?: string
  externalId?: string
  id: number
  totalPrice: string
  totalTax?: string
  subtotalPrice?: string
  status: OrderStatus
  storeId: number
  transactionId: number
  updatedAt: string
  products?: IProductSnapshot[]
  contest?: IContest
}

export enum OrderStatus {
  DRAFT = 'draft',
  PAID = 'paid',
  UNPAID = 'unpaid',
  CONTESTED = 'contested',
  REVIEWED = 'reviewed',
  PAYMENT_PROCESSING = 'paymentProcessing',
  UNKNOWN = 'unknown'
}

export interface IProduct {
  barcode?: string
  category?: string
  createdAt?: string
  deletedAt?: string
  id: string
  name?: string
  displayName?: string
  price: string
  productId?: string
  quantity: number
  sku?: string
  thumbnail?: string
  updatedAt?: string
  weight?: string
  imageSrc?: string
}

export interface IProductSnapshot {
  id: string
  productId?: string
  name?: string
  price: string
  category?: string
  barcode?: string
  sku?: string
  thumbnail?: string
  weight?: string
  createdAt?: string
  updatedAt?: string
  quantity: number
  originalQuantity?: number
  issueRequired?: boolean
  categories?: AuditCategory
}

export interface ITarget {
  entryTimestamp: number
  exitTimestamp: number
  targetId: string
  imageUrl: string
}

export type IOrderState = {
  author?: string
  startTime?: number
  endTime?: number
  keyword?: string
  status?: string
  pageSize: number
  pageIndex: number
  orders?: IOrder[]
  currentOrder?: IOrder
  finalOrder?: IOrder
  contestOrder?: IOrderContest
  pagination?: IPagination
  cases?: ICase[]
  targets?: ITarget[]
  productSelectorVisible: boolean
  searchedProduct?: {
    page?: number
    keywords?: string
    items?: IProduct[]
    count?: number
  }
}

export interface IContestItem {
  productId: number
  contestedQuantity: number
}

export type CreateOrderContestPayload = {
  orderId: number
  message: string
  items: IContestItem[]
}

export interface ICreateOrderContestResponsePayload {
  rin: string
  productId: number
  originalQuantity: number
  contestedQuantity: number
  reviewedQuantity: number
}
