export const colors = {
  black: '#000000',
  white: '#FFFFFF',
  lightBlue: '#BEE3F5',
  deepBlue: {
    50: '#1D4063',
    100: '#172C49',
    200: '#2E415B',
    300: '#2E5984',
  },
  grey: {
    25: '#fdfdfd',
    50: '#F9F9F9',
    100: '#F4F6F7',
    200: '#F3F8FC',
    300: '#D8E6ED',
    400: '#CFDDE4',
    500: '#9BA9B1',
    600: '#6B737D',
  },
  red: '#ED8394',
  lightPurple: '#E9E6FF',
  purple: '#CECBFB',
  deepPurple: '#8A95F4',
}

enum TextVariants {
  Primary = 'primary',
  Secondary = 'secondary',
  Placeholder = 'placeholder',
  Caution = 'caution',
}

export interface ITextColors {
  [TextVariants.Primary]: string
  [TextVariants.Secondary]: string
  [TextVariants.Placeholder]: string
  [TextVariants.Caution]: string
}

export const textColors: ITextColors = {
  [TextVariants.Primary]: colors.deepBlue[200],
  [TextVariants.Secondary]: colors.grey[600],
  [TextVariants.Placeholder]: colors.grey[500],
  [TextVariants.Caution]: colors.red,
}
