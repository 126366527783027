import {
  CHANGE_LAYOUT,
  CHANGE_SIDEBAR_THEME,
  CHANGE_LAYOUT_MODE,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_LAYOUT_POSITION,
  CHANGE_TOPBAR_THEME,
  CHANGE_SIDEBAR_SIZE_TYPE,
  CHANGE_SIDEBAR_VIEW,
  RESET_VALUE,
} from './actionType';

export const changeLayout = (layout: string) => ({
  type: CHANGE_LAYOUT,
  payload: layout,
});

export const changeLayoutMode = (mode: string) => ({
  type: CHANGE_LAYOUT_MODE,
  payload: mode,
});

export const changeSidebarTheme = (theme: string) => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: theme,
});

export const changeLayoutWidth = (layoutWidth: string) => ({
  type: CHANGE_LAYOUT_WIDTH,
  payload: layoutWidth,
});

export const changeLayoutPosition = (layoutposition: string) => ({
  type: CHANGE_LAYOUT_POSITION,
  payload: layoutposition,
});

export const changeTopbarTheme = (topbarTheme: string) => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
});

export const changeLeftsidebarSizeType = (leftsidebarSizetype: string) => ({
  type: CHANGE_SIDEBAR_SIZE_TYPE,
  payload: leftsidebarSizetype,
});

export const changeLeftsidebarViewType = (leftsidebarViewtype: string) => ({
  type: CHANGE_SIDEBAR_VIEW,
  payload: leftsidebarViewtype,
});

export const resetValue = () => ({
  type: RESET_VALUE,
});

export type LayoutActions = ReturnType<
  typeof changeLayout
  | typeof changeLayoutMode
  | typeof changeSidebarTheme
  | typeof changeLayoutWidth
  | typeof changeLayoutPosition
  | typeof changeTopbarTheme
  | typeof changeLeftsidebarSizeType
  | typeof changeLeftsidebarViewType
  | typeof resetValue
>