import React, { FC } from 'react'

export const SeekerIcon: FC = () => (
  <svg version="1.1" viewBox="0 0 30 30">
    <circle
      className="bg"
      cx="15"
      cy="15"
      r="14.5"
      stroke="white"
      strokeWidth="1"
      strokeOpacity="0"
      fillOpacity="0.3"
      fill="53BCE855"
    />
    <circle cx="15" cy="15" r="7" fillOpacity="1" fill="#53BCE8" />
  </svg>
)
