/* eslint-disable max-len */
import React, { FC } from 'react'

interface IProps {
  playing?: boolean
  onClicked?: () => void
}

export const MediaPlay: FC<IProps> = ({ playing, onClicked }) => (
  <svg onClick={onClicked} version="1.1" viewBox="0 0 30 30">
    <circle cx="15" cy="15" r="14.5" stroke="white" strokeWidth="1" strokeOpacity="0" fillOpacity="0.2" fill="white" />
    {!playing && (
      <path
        fill="white"
        transform="translate(11.5,9.5)"
        d="M7.6,4.3c0.7,0.4,0.7,1.3,0,1.7L2,9.3C1.3,9.7,0.5,9.2,0.5,8.5l0-6.5c0-0.8,0.8-1.3,1.5-0.9L7.6,4.3z"
      />
    )}
    {playing && (
      <g transform="translate(1,9.5)">
        <path
          fill="white"
          d="M11.6,0.4L11.6,0.4c0.7,0,1.4,0.6,1.4,1.4V9c0,0.7-0.6,1.4-1.4,1.4l0,0c-0.7,0-1.4-0.6-1.4-1.4V1.7 C10.2,1,10.8,0.4,11.6,0.4z"
        />
        <path
          fill="white"
          d="M16.9,0.4L16.9,0.4c0.7,0,1.4,0.6,1.4,1.4V9c0,0.7-0.6,1.4-1.4,1.4l0,0c-0.7,0-1.4-0.6-1.4-1.4V1.7 C15.5,1,16.1,0.4,16.9,0.4z"
        />
      </g>
    )}
  </svg>
)
