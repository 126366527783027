import React, { FC } from 'react'
import { Container, SearchInput } from './styled'

interface IProps {
  searchId?: number | string
  setSearchId: (id?: number | string) => void
}

export const SearchBar: FC<IProps> = ({ setSearchId, searchId }) => {
  const handleOnChange = React.useCallback(
    (e) => {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
      e.preventDefault()
      setSearchId(e.target.value)
    },
    [setSearchId],
  )

  return (
    <Container data-test-id="SearchBar_Container">
      <SearchInput
        onChange={(e: Event) => {
          handleOnChange(e)
        }}
        value={searchId || ''}
        placeholder="Search..."
      />
    </Container>
  )
}
