import React, { useCallback } from 'react'
import { history } from 'browserHistory'

import { theme } from 'theme'
import { Button } from 'components/button'
import { ChevronIcon } from 'components/icons/chevronIcon'

export const BackButton = () => {
  const goBack = useCallback(() => {
    history.goBack()
  }, [])
  return (
    <Button
      onClick={goBack}
      kind="secondary"
      iconLeft={<ChevronIcon color={theme.colors.deepBlue[200]} direction="left" />}
      small
      outline
    >
      Back
    </Button>
  )
}
