import * as actions from './actions'
import { IMenu } from '../../interface/admin/menu'
import { IStore } from '../../interface/admin/store'

export type IAdminState = {
  menus?: IMenu[]
  stores: IStore[]
  openedMenus: string[]
  selectedMenuKey?: string
  currentStoreId?: string
}

export const initialState: IAdminState = {
  stores: [],
  openedMenus: [],
}

export const adminReducer = (state: IAdminState = initialState, action: actions.IAdminActions) => {
  switch (action.type) {
    case actions.REQUEST_MENU_SUCCESS: {
      const { menus } = action.payload
      const fn = (menus: IMenu[], parent?: IMenu) => {
        for (const menu of menus) {
          menu.parent = parent
          if (menu.children?.length) {
            fn(menu.children, menu)
          }
        }
      }
      fn(menus)
      return {
        ...state,
        menus,
      }
    }
    case actions.SELECT_MENU: {
      const paths = action.payload.path?.split('/') || []
      const parentPaths: string[] = []
      for (let i = 1; i < paths.length - 1; i++) {
        if (i === 1) {
          parentPaths.push(`/${paths[i]}`)
        } else {
          parentPaths.push(`${parentPaths[parentPaths.length - 1]}/${paths[i]}`)
        }
      }
      return {
        ...state,
        selectedMenuKey: action.payload.path,
        openedMenus: parentPaths,
      }
    }
    case actions.SET_OPENED_MENU:
      return {
        ...state,
        openedMenus: action.payload.paths,
      }
    case actions.SET_CURRENT_STORE:
      return {
        ...state,
        currentStoreId: action.payload.storeId,
      }
    case actions.REQUEST_STORES_SUCCESS:
      return {
        ...state,
        stores: action.payload.stores,
      }
    default:
      return state
  }
}
