import API from 'api/API'
import VerticalLayouts from 'Layouts/VerticalLayouts'
import { LoginPage } from 'pages/login'
import React from 'react'
import {
  BrowserRouter, Redirect, Route, Router, Switch,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { useDispatch } from 'stores'
import { requestConfig } from 'stores/store/actions'
import NonAuthLayout from 'Layouts/NonAuthLayout'
import { OrderList } from 'components/orderList'
import { OrderDetail } from 'components/orderDetail'
import { history } from './browserHistory'
import * as authActions from './stores/auth/actions'
import './App.css'
import './assets/scss/themes.scss'

const Logout = () => {
  const dispatch = useDispatch()

  dispatch(authActions.logout())

  return null
}

const RedirectToLogin = () => <Redirect to="/login" />

const publicRoutes = [
  { path: '/', component: RedirectToLogin },
  { path: '/login', component: LoginPage },
  { path: '/logout', component: Logout },
]

const authRoutes = [
  { path: '/retailer/orders', component: OrderList },
  { path: '/retailer/orders/:id', component: OrderDetail },
]

const App = () => {
  const dispatch = useDispatch()
  if (API.getAuthToken()) dispatch(requestConfig())

  return (
    <BrowserRouter>
      <ToastContainer></ToastContainer>
      <Router history={history}>
        <Switch>
          <Route exact path={publicRoutes.map(({path}) => path)}>
            <NonAuthLayout>
              <Switch>
                {
                  publicRoutes.map(({path, component}) => <Route key={path} path={path} exact component={component}/>)
                }
              </Switch>
            </NonAuthLayout>
          </Route>
          <Route exact path={authRoutes.map(({path}) => path)}>
            <VerticalLayouts>
              <Switch>
                {
                  authRoutes.map(({path, component}) => <Route key={path} path={path} exact component={component}/>)
                }
              </Switch>
            </VerticalLayouts>
          </Route>
        </Switch>
      </Router>
    </BrowserRouter>
  )
}

export default App
