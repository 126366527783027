import { StoreStatus } from 'interface/admin/store'
import API from './API'
import { IResponseBase } from '../interface/response/success.response'

export default class StoreAPI {
  static close(storeId: string): Promise<StoreStatus> {
    return API.patch(`/api/admin/v2/stores/${storeId}/status`, {
      status: StoreStatus.CLOSED_FOR_MAINTENANCE,
    })
  }

  static open(storeId: string): Promise<StoreStatus> {
    return API.patch(`/api/admin/v2/stores/${storeId}/status`, {
      status: StoreStatus.OPEN,
    })
  }

  static delete(storeId: string): Promise<StoreStatus> {
    return API.del(`/api/admin/v2/stores/${storeId}`)
  }

  static update(storeId: string, storeName?: string, productSource?: string): Promise<StoreStatus> {
    return API.put(`/api/admin/v2/stores/${storeId}`, {
      storeName: storeName || '',
      productSource: productSource || '',
    })
  }

  static create(storeId: string, storeName: string, productSource?: string): Promise<StoreStatus> {
    return API.post('/api/admin/v2/stores', {
      storeId,
      storeName,
      productSource: productSource || '',
    })
  }

  static getConfig(): Promise<IResponseBase> {
    return API.get('/api/admin/v2/config')
  }
}
