import * as R from 'ramda'
import * as actions from './actions'
import { IOrderState, OrderStatus } from './types'

export const initialState: IOrderState = {
  pageSize: 10,
  pageIndex: 0,
  author: '',
  productSelectorVisible: false,
  currentOrder: undefined,
  finalOrder: undefined,
}

export const orderReducer = (state: IOrderState = initialState, action: actions.IOrderActions) => {
  switch (action.type) {
    case actions.REQUEST_ORDERS:
      return {
        ...state,
      }
    case actions.SET_ORDERS:
      return {
        ...state,
        orders: action.payload.data,
        pagination: action.payload.pagination,
        currentOrder: undefined,
      }
    case actions.RESET_ORDERS:
      return {
        ...state,
        orders: [],
        pagination: {},
        currentOrder: undefined,
      }
    case actions.SELECT_ORDERS_PAGE:
      return {
        ...state,
        pageIndex: action.payload.page,
        pageSize: action.payload.size,
      }

    case actions.SET_CURRENT_ORDER:
      return {
        ...state,
        currentOrder: action.payload.order,
      }
    case actions.SET_FINAL_ORDER: {
      const { order } = action.payload
      if (!order) {
        return { ...state, finalOrder: undefined }
      }
      return {
        ...state,
        finalOrder: {
          ...order,
        },
      }
    }
    case actions.REQUEST_ORDER_CASES:
      return {
        ...state,
        cases: [],
        targets: [],
      }
    case actions.REQUEST_ORDER_CASES_SUCCESS:
      return {
        ...state,
        cases: action.payload?.data?.cases ?? [],
        targets: action.payload?.data?.targets ?? [],
      }
    case actions.SWITCH_CONTEST_PRODUCT_SELECTOR_VISIBLE: {
      let productSelectorVisible = !state.productSelectorVisible
      if (action.payload.visible !== undefined) {
        productSelectorVisible = action.payload.visible
      }
      return {
        ...state,
        productSelectorVisible,
      }
    }
    case actions.REQUEST_RETAILER_PRODUCTS_SUCCESS: {
      return {
        ...state,
        searchedProduct: {
          ...(state.searchedProduct || {}),
          items: action.payload.data,
          count: action.payload.totalCount,
        },
      }
    }
    case actions.SELECT_CONTEST_PRODUCT_PAGE: {
      return {
        ...state,
        searchedProduct: {
          ...(state.searchedProduct || {}),
          pageIndex: action.payload.page,
          pageSize: action.payload.size,
        },
      }
    }
    case actions.SET_CURRENT_ORDER_AS_CONTESTED: {
      if (R.isNil(state.currentOrder)) {
        return state
      }

      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          status: OrderStatus.CONTESTED,
        },
      }
    }
    case actions.SET_CONTEST_PRODUCT: {
      return {
        ...state,
        contestOrder: action.payload.contestProduct,
      }
    }
    case actions.SET_AUTHOR: {
      return {
        ...state,
        author: action.payload.author,
      }
    }
    default:
      return state
  }
}
