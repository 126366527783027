import React, { useEffect } from 'react'

export const useOutsideClick = (
  cb: (e: MouseEvent) => void,
  ref: React.MutableRefObject<HTMLElement | undefined> | React.RefObject<HTMLDivElement>,
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(e: MouseEvent) {
      if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
        cb(e)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [cb, ref])
}
