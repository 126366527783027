import styled from 'styled-components'
import is from 'styled-is'
import { theme } from 'theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  box-sizing: border-box;
  min-width: 899px;
  height: 100%;
  padding-top: 26px;
`
export const Filter = styled.div`
  display: flex;
`
export const TableCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  color: #2e415b;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  word-break: break-all;
`

export const StatusTableCell = styled(TableCell)`
  width: 600px;
  text-align: left;
`

export const StatusBoldTableCell = styled(StatusTableCell)`
  color: #000;
  font-weight: 500;
  text-align: left;
`

export const BoldTableCell = styled(TableCell)`
  color: #000;
  font-weight: 500;
`

export const TableRow = styled.div<{ isTh?: boolean }>`
  color: #2e415b;
  font-size: 16px;
  font-family: Roboto;
  display: flex;
  font-weight: normal;
  padding: 10px 16px;
  border-bottom: 1px solid #d8e6ed;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background: #f9f9f9;
  }

  ${is('isTh')`
    font-weight:500;
    border:none;
    font-size: 18px;
    padding: 0 16px;
    cursor: initial;

    &:hover {
      background: transparent;
    }
  `}
`
export const Tag = styled.span<{ status: {color: string, bgColor: string} }>`
  width: 96px;
  padding: 3px 5px;
  color: ${({ status }: { status: {color: string, bgColor: string}}) => status.color};
  text-align: center;
  background: ${(props: { status:  {color: string, bgColor: string} }) => props.status.bgColor};
  border-radius: 4px;
`
export const TimeText = styled.span`
  color: #c5c7cd;
  line-height: 20px;
  letter-spacing: 0.1px;
  cursor: default;
`
export const DateText = styled.span`
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  cursor: default;
`

export const TableBody = styled.div`
  max-height: calc(100% - 34px - ${theme.dims.orderList.additionalMarginBottom});
  overflow-y: auto;
`

export const Tip = styled.div`
  padding: 20px;
  text-align: center;
`

export const FilterContainer = styled.div`
  display: flex;
  gap: 44px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0 34px;
`

export const PageTitle = styled.div`
  color: #2e415b;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
`

export const FilterConditions = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 16px;
  padding: 0 36px;
`

export const FilterTag = styled.div`
  display: flex;
  gap: 6px;

  padding: 7px 14px;
  color: ${theme.colors.grey[500]};
  font-size: 15px;
  line-height: 24px;
  border: 1px solid ${theme.colors.grey[300]};
  border-radius: 4px;

  &:hover {
    color: ${theme.colors.grey[600]};
    background-color: ${theme.colors.grey[50]};

    > span > svg > path {
      fill: ${theme.colors.grey[600]};
    }
  }
`

export const RemoveBtn = styled.span`
  cursor: pointer;
`
