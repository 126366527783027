import { AuditCategory } from 'interface/audits/audit'

export const AUDIT_FORM_INITIAL_VALUES = {
  comment: '',
}

export const MAP_AUDIT_CATEGORY_TO_VALUE = {
  [AuditCategory.OPERATOR_MISTAKE]: 'Operator Mistake',
  [AuditCategory.PLANOGRAM_ERROR]: 'Planogram Error',
  [AuditCategory.CAMERA_VIEW]: 'Camera View',
  [AuditCategory.SYSTEM_EVENT_LOST]: 'System - Event Lost',
  [AuditCategory.SYSTEM_HAT_ERROR]: 'System - HAT Error',
  [AuditCategory.SYSTEM_REID_TRACKING]: 'System - ReID & Tracking',
  [AuditCategory.SCHEDULED_DOWNTIME]: 'Scheduled Downtime',
  [AuditCategory.UNSCHEDULED_DOWNTIME]: 'Unscheduled Downtime',
  [AuditCategory.NO_ISSUE]: 'No Issue',
  [AuditCategory.OTHER]: 'Other',
  [AuditCategory.SYSTEM_IN_N_OUT]: 'System - In-n-Out',
  [AuditCategory.LIGHTING_ISSUE]: 'Lighting Issue',
  [AuditCategory.TESTER_ERROR_MISSING_PRODUCT]: 'Tester Error - Missing Product',
  [AuditCategory.TESTER_ERROR_WRONG_PRODUCT]: 'Tester Error - Wrong Product',
  [AuditCategory.TESTER_ERROR_OTHER]: 'Tester Error - Other',
}
