import { css } from 'styled-components'
import { theme } from 'styled-tools'

export const PrimaryStyle = css`
  color: ${theme('colors.white')};
  background-color: ${theme('colors.deepBlue.300')};

  &:disabled {
    background-color: ${theme('colors.grey.300')};
  }

  &:hover:not(:disabled) {
    background-color: ${theme('colors.deepBlue.50')};
  }
`
