import React, { FC } from 'react'
import { Button } from 'components/button'
import { EditIcon } from 'components/icons/edit'
import { theme } from 'theme'

interface IProps {
  isAuditEmpty: boolean
  isInAuditMode: boolean
  onAudit: () => void
}

export const AuditButton: FC<IProps> = ({
  isAuditEmpty, isInAuditMode, onAudit,
}) =>
  (isAuditEmpty ? (
    <Button disabled={isInAuditMode} onClick={onAudit} kind="primary" iconLeft={<EditIcon fillColor={theme.colors.white} />}>
      Audit
    </Button>
  ) : null)
