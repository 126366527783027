import React, { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { IAppState } from 'stores'
import { OrderStatus } from 'stores/orders/types'
import { selectHasInternalAuditsRole } from 'stores/auth/selectors'
import { ReceiptAmount } from './components/ReceiptAmount'
import { ReceiptStatus } from './components/ReceiptStatusToggle'
import { RefundAmount } from './components/RefundAmount'
import { RefundControls } from './components/RefundControls'
import { RequiredMsg, SummaryWrapper } from './style'

interface IOrderSummaryProps {
  currentQuantity: number
  totalTax: number
  currencySymbol: string
  originalTotalPrice: number
  finalQuantity: number
  revisedProductsSum: number
  reviewedTotalPrice: number
  auditProductsSum: number
  refundAmount: number
  onRefundChange: (e: React.FormEvent<HTMLInputElement>) => void
  hasChanged: boolean
  onCancel: () => void
  onSubmit: () => void
  orderStatus: OrderStatus
  receiptStatus: ReceiptStatus
  issueRequired: boolean
  isInAuditMode: boolean
  showTax: boolean
  isAuditEmpty: boolean
}

export const OrderSummary: FC<IOrderSummaryProps> = ({
  currentQuantity,
  currencySymbol,
  finalQuantity,
  totalTax,
  originalTotalPrice,
  revisedProductsSum,
  reviewedTotalPrice,
  auditProductsSum,
  refundAmount,
  onRefundChange,
  hasChanged,
  onCancel,
  onSubmit,
  orderStatus,
  receiptStatus,
  issueRequired,
  isInAuditMode,
  showTax,
  isAuditEmpty,
}) => {
  const priceIncludesTax = useSelector((state: IAppState) => state.store.config.product.priceIncludesTax)
  const hasInternalAuditRole = useSelector(selectHasInternalAuditsRole)

  const isPending = orderStatus === OrderStatus.CONTESTED
  const isPaid = orderStatus === OrderStatus.PAID
  const isReviewed = orderStatus === OrderStatus.REVIEWED

  const showAuditedTotal = isInAuditMode && !isAuditEmpty
  const showRevised = (receiptStatus === ReceiptStatus.REVISED && hasChanged) || showAuditedTotal

  const showRefundAmount = !isInAuditMode && (isPending || isReviewed) && receiptStatus === ReceiptStatus.REVISED
  const showRefundControls = isPending && receiptStatus === ReceiptStatus.REVISED

  const getReviewedSummaryTitle = useCallback(() => {
    if ((isReviewed && !isInAuditMode) || priceIncludesTax) {
      return 'Revised Total'
    }
    return 'Revised Subtotal'
  }, [isReviewed, isInAuditMode, priceIncludesTax])

  const getRevisedSummaryPrice = useCallback(() => {
    if (isReviewed && !isInAuditMode) {
      return reviewedTotalPrice
    }
    return isInAuditMode ? auditProductsSum : revisedProductsSum
  }, [isReviewed, isInAuditMode, reviewedTotalPrice, auditProductsSum, revisedProductsSum])

  return (
    <SummaryWrapper>
      {hasInternalAuditRole && issueRequired && isInAuditMode && receiptStatus === ReceiptStatus.REVISED && (
        <RequiredMsg>To submit please report any pending issue.</RequiredMsg>
      )}
      <ReceiptAmount
        title="Original Total"
        quantity={currentQuantity}
        currencySymbol={currencySymbol}
        price={originalTotalPrice}
        darkFont={isPaid}
        lineThrough={!isPaid && hasChanged && receiptStatus === ReceiptStatus.REVISED}
      />
      {showTax && <ReceiptAmount title="Tax Total" currencySymbol={currencySymbol} price={totalTax} />}
      {showRevised && (
        <ReceiptAmount
          title={getReviewedSummaryTitle()}
          quantity={finalQuantity}
          currencySymbol={currencySymbol}
          price={getRevisedSummaryPrice()}
          darkFont
        />
      )}
      {showRefundAmount && (
        <RefundAmount
          currencySymbol={currencySymbol}
          originalTotalPrice={originalTotalPrice}
          refundAmount={refundAmount}
          onRefundChange={onRefundChange}
          isPending={isPending}
        />
      )}
      {showRefundControls && showRefundAmount && (
        <RefundControls
          onCancel={onCancel}
          onSubmit={onSubmit}
          refundAmount={refundAmount}
          originalTotalPrice={originalTotalPrice}
        />
      )}
    </SummaryWrapper>
  )
}
