import { CopyIcon } from 'components/icons/copy'
import { SubTitle, SubTitleContainer } from 'components/orderDetail/style'
import dayjs from 'dayjs'
import copy from 'copy-to-clipboard'
import React, { FC, useCallback, useMemo } from 'react'
import { toast } from 'react-toastify'
import ReactTooltip from 'react-tooltip'

import { OrderStatus, IOrder } from 'stores/orders/types'
import { BackButton } from 'components/common/BackButton'
import TableContainer from 'components/common/tableContainer'
import { ORDER_STATUS_TO_COLOR, ORDER_STATUS_TO_LABEL } from 'components/orderList/utils'
import { DateText, Tag, TimeText } from '../../../orderList/style'
import { Container } from '../contestProduct/style'

interface IProps {
  order: IOrder
  entranceTime?: number
  exitTime?: number
  paymentId?: string
}

interface ITableData {
  id: number
  paymentId?: string
  externalId?: string
  entranceTime?: number
  exitTime?: number
  status: OrderStatus
}

export const General: FC<IProps> = ({
  order, entranceTime, exitTime, paymentId,
}) => {
  const copyPaymentId = useCallback((txt, label) => {
    if (txt) {
      copy(txt)
        && toast.success(`The ${label} have been copied.`, {
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: false,
          progress: 0,
        })
    }
  }, [])

  const columns = useMemo(() => [
    {
      Header: 'Receipt ID',
      accessor: 'id',
      Cell: (props: { row: { original: ITableData } }) => {
        const {id} = props.row.original

        return (
          <CopyIcon
            tip="Click to copy receipt ID"
            onClick={() => copyPaymentId(id, 'Receipt ID')}
            title={`${id}`}
          />
        )
      },
    },
    {
      Header: 'Payment ID',
      accessor: 'paymentId',
      Cell: (props: { row: { original: ITableData } }) => {
        const {paymentId} = props.row.original

        return (
          paymentId ? (<CopyIcon
            tip="Click to copy payment ID"
            onClick={() => copyPaymentId(paymentId, 'Payment ID')}
            title={`${paymentId.substr(0, 6)}...`}
          />
          ) : '-'
        )
      },
    },
    {
      Header: 'Order ID',
      accessor: 'externalId',
      Cell: (props: { row: { original: ITableData } }) => {
        const {externalId} = props.row.original

        return (
          externalId ? (<CopyIcon
            tip="Click to copy order ID"
            onClick={() => copyPaymentId(externalId, 'Order ID')}
            title={`${externalId.substr(0, 6)}...`}
          />
          ) : '-'
        )
      },
    },
    {
      Header: 'Entrance',
      accessor: 'entranceTime',
      Cell: (props: { row: { original: ITableData } }) => {
        const {entranceTime} = props.row.original;

        return  (
          <div>
            <DateText>
              {entranceTime ? dayjs(Number(entranceTime) * 1000).format('MMM D, YYYY'): '-'}
            </DateText>
            <TimeText>
              {entranceTime ? dayjs(Number(entranceTime) * 1000).format('h:mm:ss A'): ''}
            </TimeText>
          </div>
        )
      },
    },
    {
      Header: 'Exit',
      accessor: 'exitTime',
      Cell: (props: { row: { original: ITableData } }) => {
        const {exitTime} = props.row.original;

        return (
          <div>
            <DateText>
              {exitTime ? dayjs(Number(exitTime) * 1000).format('MMM D, YYYY'): '-'}
            </DateText>
            <TimeText>
              {exitTime ? dayjs(Number(exitTime) * 1000).format('h:mm:ss A'): ''}
            </TimeText>
          </div>
        )
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (props: { row: { original: ITableData } }) => {
        const order = props.row.original;

        return (
          <Tag status={ORDER_STATUS_TO_COLOR[order.status]}>
            {ORDER_STATUS_TO_LABEL[order.status]}
          </Tag>
        )
      },
    },
  ], [])

  const details: ITableData[] = [
    {
      id: order.id,
      paymentId,
      externalId: order.externalId,
      entranceTime,
      exitTime,
      status: order.status,
    }]

  return (
    <Container>
      <ReactTooltip />
      <SubTitleContainer>
        <BackButton />
        <SubTitle>Order Details</SubTitle>
      </SubTitleContainer>
      <TableContainer
        columns={columns}
        data={details}
        pagination={null}
        divClass="table-responsive mb-1"
        tableClass="align-middle table-nowrap"
        theadClass="table-light text-muted"
      />
    </Container>
  )
}
