import { css } from 'styled-components'
import { ifProp, theme } from 'styled-tools'

export const SecondaryStyle = css`
  background-color: ${theme('colors.white')};
  color: ${theme('colors.deepBlue.200')};

  &:disabled {
    color: ${theme('colors.grey.500')};
  }

  &:hover:not(:disabled) {
    background-color: ${theme('colors.grey.300')};
  }

  ${ifProp(
    'outline',
    css`
      border: 1px solid ${theme('colors.grey.300')};
    `,
  )}
`
