import { IAuthData } from '../../interface/request/authdata.request'

export const REQUEST_LOGIN = 'auth/request_login' as const

export const requestLogin = (authData: IAuthData) => ({
  type: REQUEST_LOGIN,
  payload: {
    authData,
  },
})

export const REQUEST_LOGIN_SUCCESS = 'auth/request_login_success' as const

export const requestLoginSuccess = (token: string, username: string, expireAt: Date) => ({
  type: REQUEST_LOGIN_SUCCESS,
  payload: {
    token,
    username,
    expireAt,
  },
})

export const REQUEST_LOGIN_FAILED = 'auth/request_login_failed' as const

export const requestLoginFailed = (response: { code: number, msg: string }) => ({
  type: REQUEST_LOGIN_FAILED,
  payload: {
    response,
  },
})
export const CHECK_AUTH = 'auth/check' as const

export const checkAuth = () => ({
  type: CHECK_AUTH,
  payload: {},
})

export const REQUEST_LOGOUT = 'auth/logout' as const

export const logout = () => ({
  type: REQUEST_LOGOUT,
  payload: {},
})

export type IAuthActions = ReturnType<
  typeof requestLogin | typeof requestLoginSuccess | typeof logout | typeof requestLoginFailed | typeof checkAuth
>
