import is, { match } from 'styled-is'
import styled from 'styled-components'
import LoginBg from 'assets/icons/login_bg.png'

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  color: #2e415b;
  background: url('${LoginBg}') no-repeat;
  background-size: cover;
`

export const Cover = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  color: #2e415b;
  background: rgba(46, 65, 91, 0.74);
`

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const LoginPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  text-align: center;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

export const LoginButton = styled.button<{ status?: boolean }>`
  width: 100%;
  padding: 20px;
  color: white;
  font-size: 16px;
  background: #40b4e5;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  ${match('status', 'error')`
    background: #FFE6EA;
    color: #EB5757;
  `};
  &:hover {
    color: #fff;
    background: #2e415b;
  }
`

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 24px;
  font-size: 14px;
  border-radius: 22px;
`
export const AccountLabel = styled.div`
  flex: 1;
  padding: 10px 0;
  color: #adadad;
  font-weight: 500;
  font-size: 14px;
`

export const AccountInput = styled.input<{ isError?: boolean }>`
  width: 100%;
  padding: 20px;
  font-size: 16px;
  background: #fff;
  height: 48px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;
  &::-webkit-input-placeholder {
    color: #cdcdcd;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:before {
    content: 'User';
  }
  &:hover,
  &:focus {
    border-color: #40b4e5;
    border-width: 2px;
  }
  ${is('isError')`
    &::placeholder {
      color: #EB5757;
    }
    &:focus {
      border-color: #EB5757;
      border-width: 2px;
    }
  `}
`

export const Title = styled.div`
  width: 100%;
  margin-bottom: 30px;
  font-size: 33.2113px;
  line-height: 39px;
  text-align: left;
`

export const NormalTitle = styled.div`
  width: 100%;
  color: #000;
`

export const ColorTitle = styled.div`
  width: 100%;
  color: #40b4e5;
  font-weight: 500;
`
