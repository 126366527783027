import { MediaPlay } from 'components/icons/mediaPlay'
import { SeekerIcon } from 'components/icons/seekerIcon'
import React, {
  FC, useCallback, useEffect, useRef, useState,
} from 'react'
import loadingAni from 'assets/icons/loadingAni.svg'
import {
  PlayButton, PlayerContainer, PlayerView, ControlBar, SeekBar, Timer,
} from './styled'

interface IProps {
  video: string
}

export const CasePlayer: FC<IProps> = ({ video }) => {
  const playerRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const barRef = useRef<HTMLDivElement>(null)
  const [playing, setPlaying] = useState(false)
  const [duration, setDuration] = useState(1)
  const [currentTime, setCurrentTime] = useState(0)
  const [loading, setLoading] = useState(true)
  const timer = useRef<NodeJS.Timeout>()

  const switchPlayingState = useCallback(() => {
    setPlaying(!playing)
  }, [playing])

  useEffect(() => {
    setPlaying(false)
    setLoading(true)
  }, [video])

  useEffect(() => {
    if (playerRef.current) {
      if (playing) {
        try {
          playerRef.current.play()
          // eslint-disable-next-line no-empty
        } catch (e) {}
      } else {
        try {
          playerRef.current.pause()
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
    }
  }, [playing])

  useEffect(() => {
    if (playerRef.current) {
      timer.current = setInterval(() => {
        setCurrentTime(playerRef.current?.currentTime || 0)
      }, 20)
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current)
      }
    }
  }, [])

  return (
    <PlayerContainer>
      <PlayerView>
        <video
          ref={playerRef}
          poster={loadingAni}
          src={video}
          loop={false}
          muted
          onEnded={() => {
            setPlaying(false)
            setTimeout(() => {
              setPlaying(true)
            }, 500)
          }}
          onCanPlayThrough={() => {
            setDuration(playerRef.current?.duration || 1)
            if (loading) {
              setPlaying(true)
            }
            setLoading(false)
          }}
        >
        </video>
        <canvas width="1280" height="720" ref={canvasRef}></canvas>
      </PlayerView>
      <ControlBar>
        <PlayButton>
          <MediaPlay playing={playing} onClicked={switchPlayingState}></MediaPlay>
        </PlayButton>
        <SeekBar
          ref={barRef}
          offset={((barRef.current?.clientWidth || 0) * currentTime) / duration}
          onClick={(e: { nativeEvent: { offsetX: number } }) => {
            if (!barRef.current?.clientWidth || !playerRef.current) {
              return
            }
            playerRef.current.currentTime = (duration * e.nativeEvent.offsetX) / barRef.current.clientWidth
            setPlaying(false)
          }}
        >
          <SeekerIcon></SeekerIcon>
        </SeekBar>
        <Timer>
          {`${Math.floor(currentTime / 60)}:${`${Math.floor(currentTime % 60)}`.padStart(2, '0')}`}
        </Timer>
      </ControlBar>
    </PlayerContainer>
  )
}
