import React, { FC, ReactNode } from 'react'
import { StyledButton } from './StyledButton'

export interface IButtonProps {
  kind: 'primary' | 'secondary' | 'secondaryCaution'
  outline?: boolean
  small?: boolean
  children: ReactNode
  onClick?: () => void
  iconLeft?: ReactNode
  iconRight?: ReactNode
  disabled?: boolean
  color?: string
  bgColor?: string
}

export const Button: FC<IButtonProps> = ({
  kind,
  small,
  outline,
  children,
  onClick,
  iconLeft,
  iconRight,
  disabled,
  color,
  bgColor,
}: IButtonProps) => (
  <StyledButton
    kind={kind}
    type="button"
    outline={outline}
    onClick={disabled ? null : onClick}
    small={small}
    disabled={disabled}
    style={{ color, backgroundColor: bgColor }}
  >
    {iconLeft}
    {children}
    {iconRight}
  </StyledButton>
)
