/* eslint-disable max-len */
import React, { FC } from 'react'

interface IProps {
  fillColor?: string
  size?: number
}

export const CameraIcon: FC<IProps> = ({ fillColor, size = 15 }) => (
  <svg width={size} height={size} viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.47 0.936031L12.6 5.13056V2.395C12.6 1.39196 11.79 0.571289 10.8 0.571289L1.8 0.571289C0.81 0.571289 0 1.39196 0 2.395L0 11.5135C0 12.5166 0.81 13.3372 1.8 13.3372L10.8 13.3372C11.79 13.3372 12.6 12.5166 12.6 11.5135V9.2339L16.47 13.1549C16.83 13.5196 17.37 13.5196 17.73 13.1549C17.91 12.9725 18 12.7901 18 12.5166L18 1.57433C18 1.02722 17.64 0.662474 17.1 0.662474C16.83 0.662474 16.65 0.75366 16.47 0.936031Z"
      fill={fillColor}
      fillOpacity="0.8"
    />
  </svg>
)
