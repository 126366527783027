import React, {
  FC, useCallback, useEffect, useRef, useState,
} from 'react'
import { useDispatch } from 'react-redux'
import searchSvg from 'assets/icons/search-icon.svg'
import OrderAPI from 'api/order'
import { IOrder, IProductSnapshot } from 'stores/orders/types'
import { setFinalOrder } from 'stores/orders/actions'
import { toast } from 'react-toastify'
import { debounce } from 'lodash'
import { useOutsideClick } from 'hooks/useOutsideClick'
import {
  Icon, SearchInput, SearchInputClear, SearchItem, SearchItemInfo, SearchResult,
} from '../style'
import { findProduct } from '../../productSelector'

interface IProps {
  currencySymbol: string
  finalOrder: IOrder
}

const SEARCH_BAR_ID = 'products-search-bar'

export const SearchBar: FC<IProps> = ({ currencySymbol, finalOrder }) => {
  const dispatch = useDispatch()

  const [productName, setProductName] = useState<string>('')
  const [searchProducts, setSearchProducts] = useState<IProductSnapshot[]>([])
  const [resultsVisible, setResultsVisible] = useState<boolean>(false)
  const showResults = () => setResultsVisible(true)

  const getRetailerProducts = useCallback(
    debounce(
      (name: string) =>
        OrderAPI.getRetailerProducts(name, finalOrder.createdAt)
          .then(({ data }) => setSearchProducts(data))
          .catch((error) => toast.error(error.message)),
      1000,
    ),
    [],
  )

  useEffect(() => {
    if (productName) {
      getRetailerProducts(productName)
    } else {
      setSearchProducts([])
    }
  }, [productName, getRetailerProducts])

  useEffect(() => {
    showResults()
  }, [searchProducts])

  const onSelectResult = useCallback(
    (product: IProductSnapshot) => {
      if (findProduct(finalOrder?.products, product.productId!)) return

      setProductName('')
      const products: IProductSnapshot[] = [...(finalOrder?.products || []), { ...product, quantity: 1 }]

      dispatch(
        setFinalOrder({
          ...finalOrder,
          products,
        }),
      )
    },
    [dispatch, finalOrder],
  )

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    setProductName(e.currentTarget.value)
  }

  const dropdownMenuRef = useRef<HTMLDivElement>(null)

  useOutsideClick((e) => {
    const ids = e.composedPath().map((i) => (i as HTMLInputElement).id)
    if (!ids.includes(SEARCH_BAR_ID)) {
      setResultsVisible(false)
    }
  }, dropdownMenuRef)

  return (
    <>
      <div style={{ position: 'relative', width: '100%' }}>
        <Icon src={searchSvg}></Icon>
        <SearchInput
          id={SEARCH_BAR_ID}
          type="text"
          value={productName}
          placeholder="Search to add new product"
          isFocus={!!productName}
          onChange={onChange}
          onFocus={showResults}
        />
        {!!productName && <SearchInputClear onClick={() => setProductName('')}>x</SearchInputClear>}
      </div>
      {resultsVisible && !!searchProducts.length && (
        <SearchResult ref={dropdownMenuRef}>
          {searchProducts.map((p) => (
            <SearchItem key={p.productId} onClick={() => onSelectResult(p)}>
              <SearchItemInfo>
                <img src={p.thumbnail || ''} alt="" width="20" height="20" />
                <span style={{ paddingLeft: '5px' }}>
                  {p.name}
                </span>
              </SearchItemInfo>
              <div>
                {currencySymbol}
                {p.price}
              </div>
            </SearchItem>
          ))}
        </SearchResult>
      )}
    </>
  )
}
