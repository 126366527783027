// @flow
import {
  all, call, fork, takeEvery,
} from 'redux-saga/effects'
import {
  layoutTypes,
} from 'VelzonComponents/constants/layout'
import {
  changeLayout,
  changeLayoutMode,
  changeLayoutPosition,
  changeLayoutWidth,
  changeLeftsidebarSizeType,
  changeLeftsidebarViewType,
  changeSidebarTheme,
  changeTopbarTheme,
} from './action'

import {
  CHANGE_LAYOUT,
  CHANGE_SIDEBAR_THEME,
  CHANGE_LAYOUT_MODE,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_LAYOUT_POSITION,
  CHANGE_TOPBAR_THEME,
  CHANGE_SIDEBAR_SIZE_TYPE,
  CHANGE_SIDEBAR_VIEW,
} from './actionType'

/**
 * Changes the body attribute
 */
function changeHTMLAttribute(attribute: string, value: string) {
  if (document.documentElement) { document.documentElement.setAttribute(attribute, value) }
  return true
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* sagaChangeLayoutTheme(action: ReturnType<typeof changeLayout>) {
  const { payload: layout } = action
  try {
    if (layout === layoutTypes.TWOCOLUMN) {
      document.documentElement.removeAttribute('data-layout-width')
    } else if (layout === layoutTypes.HORIZONTAL) {
      document.documentElement.removeAttribute('data-sidebar-size')
    }
    yield call(changeHTMLAttribute, 'data-layout', layout)
  } catch (error) {
    // console.log(error);
  }
}

/**
 * Changes the layout mode
 * @param {*} param0
 */
function* sagaChangeLayoutMode(action: ReturnType<typeof changeLayoutMode>) {
  const { payload: mode } = action
  try {
    yield call(changeHTMLAttribute, 'data-layout-mode', mode)
  } catch (error) {
    // console.log(error);
  }
}

/**
 * Changes the left sidebar theme
 * @param {*} param0
 */
function* sagaChangeLeftSidebarTheme(action: ReturnType<typeof changeSidebarTheme>) {
  const { payload: theme } = action
  try {
    yield call(changeHTMLAttribute, 'data-sidebar', theme)
  } catch (error) {
    // console.log(error);
  }
}

/**
 * Changes the layout width
 * @param {*} param0
 */
function* sagaChangeLayoutWidth(action: ReturnType<typeof changeLayoutWidth>) {
  const { payload: layoutWidth } = action
  try {
    if (layoutWidth === 'lg') {
      yield call(changeHTMLAttribute, 'data-layout-width', 'fluid')
    } else {
      yield call(changeHTMLAttribute, 'data-layout-width', 'boxed')
    }
    // yield call(changeHTMLAttribute, "data-sidebar-size", layoutWidth);
  } catch (error) {
    // console.log(error);
  }
}

/**
 * Changes the layout position
 * @param {*} param0
 */
function* sagaChangeLayoutPosition(action: ReturnType<typeof changeLayoutPosition>) {
  const { payload: layoutposition } = action
  try {
    yield call(changeHTMLAttribute, 'data-layout-position', layoutposition)
  } catch (error) {
    // console.log(error);
  }
}

/**
 * Changes the topbar themes
 * @param {*} param0
 */
function* sagaChangeTopbarTheme(action: ReturnType<typeof changeTopbarTheme>) {
  const { payload: topbarTheme } = action
  try {
    yield call(changeHTMLAttribute, 'data-topbar', topbarTheme)
  } catch (error) {
    // console.log(error);
  }
}

/**
 * Changes the topbar themes
 * @param {*} param0
 */
function* sagaChangeLeftsidebarSizeType(action: ReturnType<typeof changeLeftsidebarSizeType>) {
  const { payload: leftsidebarSizetype } = action
  try {
    switch (leftsidebarSizetype) {
      case 'lg':
        yield call(changeHTMLAttribute, 'data-sidebar-size', 'lg')
        break
      case 'md':
        yield call(changeHTMLAttribute, 'data-sidebar-size', 'md')
        break
      case 'sm':
        yield call(changeHTMLAttribute, 'data-sidebar-size', 'sm')
        break
      case 'sm-hover':
        yield call(changeHTMLAttribute, 'data-sidebar-size', 'sm-hover')
        break
      default:
        yield call(changeHTMLAttribute, 'data-sidebar-size', 'lg')
    }
  } catch (error) {
    // console.log(error);
  }
}

/**
 * Changes the topbar themes
 * @param {*} param0
 */
function* sagaChangeLeftSidebarViewType(action: ReturnType<typeof changeLeftsidebarViewType>) {
  const { payload: leftsidebarViewtype } = action
  try {
    yield call(changeHTMLAttribute, 'data-layout-style', leftsidebarViewtype)
  } catch (error) {
    // console.log(error);
  }
}

/**
 * Watchers
 */
export function* watchChangeLayoutType() {
  yield takeEvery(CHANGE_LAYOUT, sagaChangeLayoutTheme)
}
export function* watchChangeLayoutMode() {
  yield takeEvery(CHANGE_LAYOUT_MODE, sagaChangeLayoutMode)
}
export function* watchChangeLeftSidebarTheme() {
  yield takeEvery(CHANGE_SIDEBAR_THEME, sagaChangeLeftSidebarTheme)
}
export function* watchChangeLayoutWidth() {
  yield takeEvery(CHANGE_LAYOUT_WIDTH, sagaChangeLayoutWidth)
}
export function* watchChangeLayoutPosition() {
  yield takeEvery(CHANGE_LAYOUT_POSITION, sagaChangeLayoutPosition)
}
export function* watchChangeTopbarTheme() {
  yield takeEvery(CHANGE_TOPBAR_THEME, sagaChangeTopbarTheme)
}
export function* watchChangeLeftsidebarSizeType() {
  yield takeEvery(CHANGE_SIDEBAR_SIZE_TYPE, sagaChangeLeftsidebarSizeType)
}
export function* watchChangeLeftSidebarViewType() {
  yield takeEvery(CHANGE_SIDEBAR_VIEW, sagaChangeLeftSidebarViewType)
}

function* LayoutSaga() {
  yield all([
    fork(watchChangeLayoutType),
    fork(watchChangeLeftSidebarTheme),
    fork(watchChangeLayoutMode),
    fork(watchChangeLayoutWidth),
    fork(watchChangeLayoutPosition),
    fork(watchChangeTopbarTheme),
    fork(watchChangeLeftsidebarSizeType),
    fork(watchChangeLeftSidebarViewType),
  ])
}

export default LayoutSaga
