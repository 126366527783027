import React, { FC } from 'react'
import { Header } from 'components/typography/Header'
import { StyledTextarea } from './StyledTextarea'

interface IProps {
  comment: string
}

export const AuditBoxReadOnly: FC<IProps> = ({ comment }) => (
  <>
    <Header as="h4" color="primary">
      Audition Comments
    </Header>
    <StyledTextarea disabled value={comment ?? ''}></StyledTextarea>
  </>
)
