import API from './API'
import { IResponse } from '../interface/response/success.response'
import { IAuthData } from '../interface/request/authdata.request'
import { IAuthResponse } from '../interface/response/auth.response'

export default class AuthAPI {
  static authorize(authData: IAuthData): Promise<IResponse<IAuthResponse>> {
    return API.post('/admin/authorization', authData)
  }
}
