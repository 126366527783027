import React, { FC } from 'react'
import { SwitchOption, SwitchWrap } from '../style'

export enum ReceiptStatus {
  REVISED = 'Revised',
  ORIGINAL = 'Original',
}

interface IProps {
  receiptStatus: ReceiptStatus
  setReceiptStatus: (receiptStatus: ReceiptStatus) => void
}

export const ReceiptStatusToggle: FC<IProps> = ({ receiptStatus, setReceiptStatus }) => (
  <SwitchWrap>
    <SwitchOption onClick={() => setReceiptStatus(ReceiptStatus.REVISED)} isActive={receiptStatus === ReceiptStatus.REVISED}>
      Revised
    </SwitchOption>
    <SwitchOption onClick={() => setReceiptStatus(ReceiptStatus.ORIGINAL)} isActive={receiptStatus === ReceiptStatus.ORIGINAL}>
      Original
    </SwitchOption>
  </SwitchWrap>
)
