import styled from 'styled-components'
import { theme } from 'styled-tools'

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0px 16px;
  color: ${theme('textColors.primary')};
  &:hover {
    background-color: ${theme('colors.lightBlue')};
  }

  > input {
    margin-right: 5px;
  }
`
