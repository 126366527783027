import React, {
  FC, useCallback, useEffect, useReducer, useState,
} from 'react'
import Arrow from 'assets/icons/arrow.svg'
import Reset from 'assets/icons/reset.svg'
import { FlexColumn } from 'components/common/layout'
import { Datepicker, START_DATE } from '@datepicker-react/styled'
import { ThemeProvider } from 'styled-components'
import { SelectorContainer, Text } from '../storeSelector/style'
import {
  InputContainer, DateBox, DateFooter, Cancel, Apply, RowCenter, TagName, DateInput, ResetIcon,
} from './style'
import { Nullable } from '..'
import './date.css'

interface IProps {
  title: string
  setSelected?: (value: { start: Nullable<Date>, end: Nullable<Date> }) => void
  setSelector: (selector?: string) => void
  selector?: string
}

const initialState = {
  startDate: null,
  endDate: null,
  focusedInput: START_DATE,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function reducer(state: any, action: any) {
  switch (action.type) {
    case 'focusChange':
      return { ...state, focusedInput: action.payload }
    case 'dateChange':
      return action.payload
    default:
      throw new Error()
  }
}

const dataPickerTheme = {
  breakpoints: ['32em', '48em', '64em'],
  reactDatepicker: {
    daySize: [36, 40],
    fontFamily: 'system-ui, -apple-system',
    colors: {
      accessibility: '#40B4E5',
      selectedDay: '#40B4E5',
      selectedDayHover: '#40B4E5',
      primaryColor: '#40B4E5',
    },
  },
}

export const DateSelector: FC<IProps> = ({
  title, setSelected, selector, setSelector,
}) => {
  const [isSelectorShow, setIsSelectorShow] = useState<boolean>(false)
  const [state, dispatch] = useReducer(reducer, initialState)

  const handleOnclick = useCallback(
    (e: Event) => {
      e.stopPropagation()
      setSelector('date')
      setIsSelectorShow(!isSelectorShow)
    },
    [isSelectorShow, setSelector],
  )

  const onDatesChange = useCallback((data) => {
    dispatch({ type: 'dateChange', payload: data })
  }, [])

  const handleCancel = () => {
    setIsSelectorShow(false)
    setSelector()
  }

  const handleApply = (e: Event) => {
    e.stopPropagation()
    setSelector('apply')
    setSelected && setSelected({ start: state.startDate, end: state.endDate })
  }

  const resetDate = useCallback(() => {
    dispatch({ type: 'dateChange', payload: initialState })
  }, [])

  const formateDate = (date: Date) => {
    if (date) {
      const year = date.getFullYear()
      const month = date.getMonth()
      const day = date.getDate()

      const m = month < 10 ? `0${month}` : month
      const d = day < 10 ? `0${day}` : day

      const orinDate = `${m}-${d}-${year}`
      return orinDate
    }
    return ''
  }

  useEffect(() => {
    if (selector !== 'date' && isSelectorShow) {
      setIsSelectorShow(false)
    }
  }, [selector, isSelectorShow])

  return (
    <SelectorContainer>
      <Text onClick={handleOnclick}>
        {title}
        &nbsp;
        <img src={Arrow} alt="" style={{ transform: isSelectorShow ? 'rotate(180deg)' : '' }} />
      </Text>
      {isSelectorShow && selector === 'date' && (
        <InputContainer id="dateSelector">
          <ThemeProvider theme={dataPickerTheme}>
            <DateBox>
              <RowCenter>
                <FlexColumn>
                  <TagName>Start</TagName>
                  <DateInput value={formateDate(state.startDate)} readOnly />
                </FlexColumn>
                <FlexColumn>
                  <RowCenter>
                    <TagName>End</TagName>
                    <RowCenter onClick={resetDate}>
                      <TagName>Reset</TagName>
                      <ResetIcon src={Reset} />
                    </RowCenter>
                  </RowCenter>
                  <DateInput value={formateDate(state.endDate)} readOnly />
                </FlexColumn>
              </RowCenter>
              <Datepicker
                maxBookingDate={new Date()}
                showResetDates={false}
                showClose={false}
                showSelectedDates={false}
                onDatesChange={onDatesChange}
                startDate={state.startDate} // Date or null
                endDate={state.endDate} // Date or null
                focusedInput={state.focusedInput}
                firstDayOfWeek={0}
              />
              <DateFooter>
                <Cancel onClick={handleCancel}>Cancel</Cancel>
                <Apply onClick={handleApply}>Apply</Apply>
              </DateFooter>
            </DateBox>
          </ThemeProvider>
        </InputContainer>
      )}
    </SelectorContainer>
  )
}
