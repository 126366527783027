import { FlexColumn, FlexRow } from 'components/common/layout'
import { Container } from 'components/orderDetail/style'
import styled from 'styled-components'
import is from 'styled-is'
import { ifProp, prop } from 'styled-tools'
import { theme } from 'theme'

export const ReceiptDetail = styled(Container)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 39.1%;
  min-width: min-content;
  min-height: 500px;
  padding: 16px 30px;
  background: ${theme.colors.grey[50]};
`

export const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 200px;
  overflow-y: auto;
  background: transparent;
`
export const Button = styled.button`
  padding: 0 15px;
  color: ${theme.colors.grey[500]};

  line-height: 28px;
  background: ${theme.colors.white};

  border: 1px solid ${theme.colors.grey[300]};
  border-radius: 5px;

  cursor: pointer;

  &:hover {
    color: #2e5984;
  }
`
export const Confirm = styled(Button)`
  color: ${theme.colors.white};
  background-color: ${theme.colors.deepBlue[300]};
  border: 1px solid ${theme.colors.deepBlue[300]};
  font-weight: 500;
  margin-left: 12px;

  ${is('disabledBtn')`
    opacity: 0.5;
    cursor: not-allowed;
  `}

  &:hover {
    color: #2e5984;
    background-color: ${theme.colors.white};
  }
`

export const TotalContainer = styled.div<{
  border?: boolean
  main?: boolean
  revised?: boolean
}>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #6b737d;

  > * {
    flex: 1;
  }

  ${ifProp(
    'border',
    `
      padding-top: 12px;
      border-top: 1px solid #ebecf3;
    `,
  )}

  ${is('main')`
    justify-content: space-between;
    align-items:center;
    color: #3E3E3E;
    font-weight: 500;
  `}
  ${is('darkFont')`
    color: #3E3E3E;
  `}
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const ContainerItem = styled.div<{ flex: number, align: string }>`
  text-align: ${(props: { align: string }) => props.align || 'left'};
  ${is('leftMargin')`
    marginLeft: '24px';
  `}
  ${is('lineThrough')`
    color: #757F8B;
    text-decoration: line-through;
  `}
  ${is('darkFont')`
    color: #3E3E3E;
  `}
`

export const SearchInput = styled.input<{ isFocus: boolean }>`
  display: block;
  background: #fff;
  border: 1px solid #dce8ef;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px;
  padding-left: 40px;
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #6b737d;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #ccc;
  }
  ${is('isFocus')`
    width: 100%;
  `}
`

export const Icon = styled.img`
  position: absolute;
  top: 12px;
  left: 12px;
`

export const SearchInputClear = styled.span`
  position: absolute;
  top: 6px;
  right: 18px;
  color: #6b737d;
  cursor: pointer;
`

export const SearchResult = styled.div`
  position: absolute;
  top: 52px;
  z-index: 10;
  width: 100%;
  max-height: 176px;
  padding: 10px 0;
  overflow-y: scroll;
  font-size: 14px;
  background: #fff;
  border: 1px solid #bdcbd3;
  border-radius: 20px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
`

export const SearchItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  &:hover {
    background: #eef6fa;
  }
`

export const SearchItemInfo = styled.div`
  display: flex;
  align-items: center;
`

export const DetailTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 16px;
  color: ${theme.colors.deepBlue[200]};
  font-weight: 500;
  font-size: 20px;
  border-bottom: 1px solid ${theme.colors.grey[300]};
`

export const TitleText = styled.div`
  color: ${theme.colors.deepBlue[200]};
  font-weight: 500;
  font-size: 20px;
`

export const BottomBox = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: ${prop('marginTop', '0')};
  padding: 16px;
  background: #fafbfd;
  border: 1px solid #dce8ef;
`

export const CommentBox = styled.div`
  padding: 16px 18px;
  background-color: #f3f8fc;
  border: 1px solid #dce8ef;
`

export const RefundInput = styled.input`
  box-sizing: border-box;

  width: 68px;
  font-size: 16px;
  font-weight: ${theme.fonts.weights.bold};
  line-height: 22px;
  text-align: right;
  padding: 3px 7px;
  background-color: ${theme.colors.white};
  color: ${theme.colors.deepBlue[200]};
  border: 1px solid #dce8ef;
  border-radius: 5px;

  ${is('refundWarning')`
    background: #FFE6EA;
    border: 1px solid #FF5C8D;
  `}
`

export const RefundContainer = styled.div`
  line-height: 20px;
`
export const CurrencySymbol = styled.span`
  position: relative;
  left: 16px;
`

export const RefundWarningTip = styled.div`
  color: #eb5757;
  font-weight: 300;
  font-size: 14px;
`
export const SwitchWrap = styled.div`
  display: flex;
  align-items: center;
  min-width: 168px;
  height: 40px;
  border: 1px solid #dce8ef;
  border-radius: 4px;
`

export const SwitchOption = styled.div`
  width: 50%;
  height: 100%;
  line-height: 40px;
  color: ${theme.colors.grey[600]};
  text-align: center;
  cursor: pointer;
  ${is('isActive')`
    background: ${theme.colors.deepBlue[300]};
    color: ${theme.colors.white};
    border-radius: 4px;
  `}
`

export const RequiredMsg = styled.span`
  color: ${theme.colors.red};
  text-align: center;
`

export const Subtitle = styled(FlexRow)`
  position: relative;
  gap: 16px;
  margin-bottom: 14px;
`

export const HeaderButtons = styled(FlexRow)`
  gap: 8px;
`

export const Footer = styled(FlexRow)`
  gap: 44px;
  justify-content: space-between;
`

export const SummaryWrapper = styled(FlexColumn)`
  gap: 12px;
  margin-top: auto;
`
