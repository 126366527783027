import { css } from 'styled-components'
import { ifProp } from 'styled-tools'
import { theme } from 'theme'

export const SecondaryCautionStyle = css`
  background-color: ${theme.colors.white};
  color: ${theme.textColors.caution};

  &:hover {
    background-color: ${theme.colors.grey[300]};
  }

  ${ifProp('outline', `border: 1px solid ${theme.colors.red};`)}
`
