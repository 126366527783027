import { IAuditPayload, IAudit, IOrderAuditsResponse } from '../interface/audits/audit'

import API from './API'

export default class AuditsAPI {
  static getOrderAudits(orderId: string | number): Promise<IOrderAuditsResponse> {
    return API.get(`/api/admin/v2/audits?orderId=${orderId}`)
  }

  static getAudit(auditId: string | number): Promise<IAudit> {
    return API.get(`/api/admin/v2/audits/${auditId}`)
  }

  static createAudit(orderId: string | number): Promise<IAudit> {
    return API.post('/api/admin/v2/audits', { orderId })
  }

  static updateAudit(auditId: string | number, audit: IAuditPayload) {
    return API.patch(`/api/admin/v2/audits/${auditId}`, audit)
  }
}
