import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Import Icons
import FeatherIcon from 'feather-icons-react';

const Navdata = () => {
  const history = useHistory();
  // state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isBaseUi, setIsBaseUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);

  // Apps
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [isProjects, setIsProjects] = useState(false);
  const [isTasks, setIsTasks] = useState(false);
  const [isCRM, setIsCRM] = useState(false);
  const [isCrypto, setIsCrypto] = useState(false);
  const [isInvoices, setIsInvoices] = useState(false);
  const [isSupportTickets, setIsSupportTickets] = useState(false);

  // Authentication
  const [isSignIn, setIsSignIn] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isLockScreen, setIsLockScreen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isVerification, setIsVerification] = useState(false);
  const [isError, setIsError] = useState(false);

  // Pages
  const [isProfile, setIsProfile] = useState(false);

  // Charts
  const [isApex, setIsApex] = useState(false);

  // Multi Level
  const [isLevel1, setIsLevel1] = useState(false);
  const [isLevel2, setIsLevel2] = useState(false);

  const [iscurrentState, setIscurrentState] = useState('Dashboard');

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute('subitems')) {
      const ul = document.getElementById('two-column-menu');
      const iconItems = ul.querySelectorAll('.nav-icon.active');
      const activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove('active');
        const id = item.getAttribute('subitems');
        if (document.getElementById(id))
          document.getElementById(id).classList.remove('show');
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel');
    if (iscurrentState !== 'Dashboard') {
      setIsDashboard(false);
    }
    if (iscurrentState !== 'Apps') {
      setIsApps(false);
    }
    if (iscurrentState !== 'Auth') {
      setIsAuth(false);
    }
    if (iscurrentState !== 'Pages') {
      setIsPages(false);
    }
    if (iscurrentState !== 'BaseUi') {
      setIsBaseUi(false);
    }
    if (iscurrentState !== 'AdvanceUi') {
      setIsAdvanceUi(false);
    }
    if (iscurrentState !== 'Forms') {
      setIsForms(false);
    }
    if (iscurrentState !== 'Tables') {
      setIsTables(false);
    }
    if (iscurrentState !== 'Charts') {
      setIsCharts(false);
    }
    if (iscurrentState !== 'Icons') {
      setIsIcons(false);
    }
    if (iscurrentState !== 'Maps') {
      setIsMaps(false);
    }
    if (iscurrentState !== 'MuliLevel') {
      setIsMultiLevel(false);
    }
    if (iscurrentState === 'Widgets') {
      history.push('/widgets');
      document.body.classList.add('twocolumn-panel');
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isApps,
    isAuth,
    isPages,
    isBaseUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isMultiLevel,
  ]);

  const menuItems = [
    {
      label: 'Menu',
      isHeader: true,
    },
    {
      id: 'orders',
      label: 'Orders',
      icon: <FeatherIcon icon="inbox" className="icon-dual" />,
      link: '/retailer/orders',
    //   stateVariables: isDashboard,
    //   click (e) {
    //     e.preventDefault();
    //     setIsDashboard(!isDashboard);
    //     setIscurrentState('Dashboard');
    //     updateIconSidebar(e);
    //   },
    //   subItems: [
    //     {
    //       id: 'analytics',
    //       label: 'Analytics',
    //       link: '/dashboard-analytics',
    //       parentId: 'dashboard',
    //     },
    //   ],
    },
    // {
    //   id: 'apps',
    //   label: 'Apps',
    //   icon: <FeatherIcon icon="grid" className="icon-dual" />,
    //   link: '/#',
    //   click (e) {
    //     e.preventDefault();
    //     setIsApps(!isApps);
    //     setIscurrentState('Apps');
    //     updateIconSidebar(e);
    //   },
    //   stateVariables: isApps,
    //   subItems: [
    //     {
    //       id: 'appsecommerce',
    //       label: 'Ecommerce',
    //       link: '/#',
    //       isChildItem: true,
    //       click (e) {
    //         e.preventDefault();
    //         setIsEcommerce(!isEcommerce);
    //       },
    //       parentId: 'apps',
    //       stateVariables: isEcommerce,
    //       childItems: [
    //         { id: 1, label: 'Products', link: '/apps-ecommerce-products', parentId: 'apps' },
    //         { id: 2, label: 'Product Details', link: '/apps-ecommerce-product-details', parentId: 'apps' },
    //         { id: 3, label: 'Create Product', link: '/apps-ecommerce-add-product', parentId: 'apps' },
    //         { id: 4, label: 'Orders', link: '/apps-ecommerce-orders', parentId: 'apps' },
    //         { id: 5, label: 'Order Details', link: '/apps-ecommerce-order-details', parentId: 'apps' },
    //         { id: 6, label: 'Customers', link: '/apps-ecommerce-customers', parentId: 'apps' },
    //         { id: 7, label: 'Shopping Cart', link: '/apps-ecommerce-cart', parentId: 'apps' },
    //         { id: 8, label: 'Checkout', link: '/apps-ecommerce-checkout', parentId: 'apps' },
    //         { id: 9, label: 'Sellers', link: '/apps-ecommerce-sellers', parentId: 'apps' },
    //         { id: 10, label: 'Seller Details', link: '/apps-ecommerce-seller-details', parentId: 'apps' },
    //       ],
    //     },
    //     {
    //       id: 'invoices',
    //       label: 'Invoices',
    //       link: '/#',
    //       isChildItem: true,
    //       click (e) {
    //         e.preventDefault();
    //         setIsInvoices(!isInvoices);
    //       },
    //       parentId: 'apps',
    //       stateVariables: isInvoices,
    //       childItems: [
    //         { id: 1, label: 'List View', link: '/apps-invoices-list' },
    //         { id: 2, label: 'Details', link: '/apps-invoices-details' },
    //         { id: 3, label: 'Create Invoice', link: '/apps-invoices-create' },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   label: 'pages',
    //   isHeader: true,
    // },
    // {
    //   id: 'authentication',
    //   label: 'Authentication',
    //   icon: <FeatherIcon icon="users" className="icon-dual" />,
    //   link: '/#',
    //   click (e) {
    //     e.preventDefault();
    //     setIsAuth(!isAuth);
    //     setIscurrentState('Auth');
    //     updateIconSidebar(e);
    //   },
    //   stateVariables: isAuth,
    //   subItems: [
    //     {
    //       id: 'signIn',
    //       label: 'Sign In',
    //       link: '/#',
    //       isChildItem: true,
    //       click (e) {
    //         e.preventDefault();
    //         setIsSignIn(!isSignIn);
    //       },
    //       parentId: 'authentication',
    //       stateVariables: isSignIn,
    //       childItems: [
    //         { id: 1, label: 'Basic', link: '/auth-signin-basic' },
    //         { id: 2, label: 'Cover', link: '/auth-signin-cover' },
    //       ],
    //     },
    //     {
    //       id: 'signUp',
    //       label: 'Sign Up',
    //       link: '/#',
    //       isChildItem: true,
    //       click (e) {
    //         e.preventDefault();
    //         setIsSignUp(!isSignUp);
    //       },
    //       parentId: 'authentication',
    //       stateVariables: isSignUp,
    //       childItems: [
    //         { id: 1, label: 'Basic', link: '/auth-signup-basic' },
    //         { id: 2, label: 'Cover', link: '/auth-signup-cover' },
    //       ],
    //     },
    //     {
    //       id: 'passwordReset',
    //       label: 'Password Reset',
    //       link: '/#',
    //       isChildItem: true,
    //       click (e) {
    //         e.preventDefault();
    //         setIsPasswordReset(!isPasswordReset);
    //       },
    //       parentId: 'authentication',
    //       stateVariables: isPasswordReset,
    //       childItems: [
    //         { id: 1, label: 'Basic', link: '/auth-pass-reset-basic' },
    //         { id: 2, label: 'Cover', link: '/auth-pass-reset-cover' },
    //       ],
    //     },
    //     {
    //       id: 'lockScreen',
    //       label: 'Lock Screen',
    //       link: '/#',
    //       isChildItem: true,
    //       click (e) {
    //         e.preventDefault();
    //         setIsLockScreen(!isLockScreen);
    //       },
    //       parentId: 'authentication',
    //       stateVariables: isLockScreen,
    //       childItems: [
    //         { id: 1, label: 'Basic', link: '/auth-lockscreen-basic' },
    //         { id: 2, label: 'Cover', link: '/auth-lockscreen-cover' },
    //       ],
    //     },
    //     {
    //       id: 'logout',
    //       label: 'Logout',
    //       link: '/#',
    //       isChildItem: true,
    //       click (e) {
    //         e.preventDefault();
    //         setIsLogout(!isLogout);
    //       },
    //       parentId: 'authentication',
    //       stateVariables: isLogout,
    //       childItems: [
    //         { id: 1, label: 'Basic', link: '/auth-logout-basic' },
    //         { id: 2, label: 'Cover', link: '/auth-logout-cover' },
    //       ],
    //     },
    //     {
    //       id: 'successMessage',
    //       label: 'Success Message',
    //       link: '/#',
    //       isChildItem: true,
    //       click (e) {
    //         e.preventDefault();
    //         setIsSuccessMessage(!isSuccessMessage);
    //       },
    //       parentId: 'authentication',
    //       stateVariables: isSuccessMessage,
    //       childItems: [
    //         { id: 1, label: 'Basic', link: '/auth-success-msg-basic' },
    //         { id: 2, label: 'Cover', link: '/auth-success-msg-cover' },
    //       ],
    //     },
    //     {
    //       id: 'twoStepVerification',
    //       label: 'Two Step Verification',
    //       link: '/#',
    //       isChildItem: true,
    //       click (e) {
    //         e.preventDefault();
    //         setIsVerification(!isVerification);
    //       },
    //       parentId: 'authentication',
    //       stateVariables: isVerification,
    //       childItems: [
    //         { id: 1, label: 'Basic', link: '/auth-twostep-basic' },
    //         { id: 2, label: 'Cover', link: '/auth-twostep-cover' },
    //       ],
    //     },
    //     {
    //       id: 'errors',
    //       label: 'Errors',
    //       link: '/#',
    //       isChildItem: true,
    //       click (e) {
    //         e.preventDefault();
    //         setIsError(!isError);
    //       },
    //       parentId: 'authentication',
    //       stateVariables: isError,
    //       childItems: [
    //         { id: 1, label: '404 Basic', link: '/auth-404-basic' },
    //         { id: 2, label: '404 Cover', link: '/auth-404-cover' },
    //         { id: 3, label: '404 Alt', link: '/auth-404-alt' },
    //         { id: 4, label: '500', link: '/auth-500' },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: 'widgets',
    //   label: 'Widgets',
    //   icon: <FeatherIcon icon="copy" className="icon-dual" />,
    //   link: '/widgets',
    //   click (e) {
    //     e.preventDefault();
    //     setIscurrentState('Widgets');
    //   },
    // },
    // {
    //   id: 'charts',
    //   label: 'Charts',
    //   icon: <FeatherIcon icon="pie-chart" className="icon-dual" />,
    //   link: '/#',
    //   click (e) {
    //     e.preventDefault();
    //     setIsCharts(!isCharts);
    //     setIscurrentState('Charts');
    //     updateIconSidebar(e);
    //   },
    //   stateVariables: isCharts,
    //   subItems: [
    //     {
    //       id: 'apexcharts',
    //       label: 'Apexcharts',
    //       link: '/#',
    //       isChildItem: true,
    //       click (e) {
    //         e.preventDefault();
    //         setIsApex(!isApex);
    //       },
    //       stateVariables: isApex,
    //       childItems: [
    //         { id: 1, label: 'Line', link: '/charts-apex-line' },
    //         { id: 2, label: 'Area', link: '/charts-apex-area' },
    //         { id: 3, label: 'Column', link: '/charts-apex-column' },
    //         { id: 4, label: 'Bar', link: '/charts-apex-bar' },
    //         { id: 5, label: 'Mixed', link: '/charts-apex-mixed' },
    //         { id: 6, label: 'Timeline', link: '/charts-apex-timeline' },
    //         { id: 7, label: 'Candlstick', link: '/charts-apex-candlestick' },
    //         { id: 8, label: 'Boxplot', link: '/charts-apex-boxplot' },
    //         { id: 9, label: 'Bubble', link: '/charts-apex-bubble' },
    //         { id: 10, label: 'Scatter', link: '/charts-apex-scatter' },
    //         { id: 11, label: 'Heatmap', link: '/charts-apex-heatmap' },
    //         { id: 12, label: 'Treemap', link: '/charts-apex-treemap' },
    //         { id: 13, label: 'Pie', link: '/charts-apex-pie' },
    //         { id: 14, label: 'Radialbar', link: '/charts-apex-radialbar' },
    //         { id: 15, label: 'Radar', link: '/charts-apex-radar' },
    //         { id: 16, label: 'Polar', link: '/charts-apex-polar' },
    //       ],
    //     },
    //     { id: 'chartjs', label: 'Chartjs', link: '/charts-chartjs', parentId: 'charts' },
    //     { id: 'echarts', label: 'Echarts', link: '/charts-echarts', parentId: 'charts' },

    //   ],
    // },
  ];
  return <>
    {menuItems}
  </>;
};
export default Navdata;