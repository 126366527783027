import { all, fork } from 'redux-saga/effects'
import LayoutSaga from 'store/layouts/saga'
import { adminSagas } from './admin/sagas'
import { auditsSagas } from './audits/sagas'

import { authSagas } from './auth/sagas'
import { orderSagas } from './orders/sagas'
import { storeSagas } from './store/sagas'

/* istanbul ignore next */
export function* rootSagas() {
  yield all([
    fork(authSagas),
    fork(orderSagas),
    fork(adminSagas),
    fork(storeSagas),
    fork(auditsSagas),
    fork(LayoutSaga),
  ])
}
