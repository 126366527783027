import Axios, { AxiosRequestConfig, AxiosInstance, AxiosError } from 'axios'
import cookie from 'react-cookies'
import { toast } from 'react-toastify'
import jwt, { JwtPayload } from 'jsonwebtoken'
import { config } from '../config'

const TIME_OUT = 6000;

const COOKIE_AUTHOR_KEY = 'authorization'
let authorKey: string | undefined

const getHeaders = (config?: AxiosRequestConfig, defaultConfig?: {'Authorization': string}) => ({
  ...config?.headers,
  ...defaultConfig,
})

const getDefaultConfig = (authorization?: string): {'Authorization': string} => {
  const authToken = authorKey || cookie.load(COOKIE_AUTHOR_KEY)
  return {
    Authorization: authorization || authToken,
  }
}

const setAuthToken = (token?: string, userName?: string, expires?: number) => {
  if (!!token) {
    Axios.defaults.headers.common.Authorization = token
    authorKey = `Bearer ${token}`
    const cookieOptions = { maxAge: expires }
    cookie.save(COOKIE_AUTHOR_KEY, authorKey, cookieOptions)
    if (userName) {
      cookie.save('userName', userName, cookieOptions)
    }
  } else {
    authorKey = undefined
    delete Axios.defaults.headers.common.Authorization
    cookie.remove(COOKIE_AUTHOR_KEY)
    cookie.remove('userName')
    cookie.remove('userId')
  }
}

const clearAuthToken = () => {
  setAuthToken()
  if (!window.location.pathname.startsWith('/login')) {
    window.location.href = '/login'
  }
}
const errorHandler = (error: AxiosError): void => {
  if (error.response?.status === 400) {
    toast.error(error.message)
    return
  }
  if (error.response?.status === 401) {
    toast.error('Your session has timed out due to inactivity')
    setTimeout(() => {
      clearAuthToken()
    }, 3000)

    return
  }
  if (error.response?.status === 403) {
    toast.error('Permission Denied')
    return
  }
  if (error.response?.status === 404) {
    toast.error('Resource not found')
    return
  }

  toast.error('Unknown Error')
}

const post = async (url: string, data?: object, config?: AxiosRequestConfig) => {
  const defaultConfig = getDefaultConfig()
  try {
    const result = await Axios.post(url, data, {
      ...config,
      headers: getHeaders(config, defaultConfig),
      timeout: TIME_OUT,
    })
    return result.data
  } catch (error) {
    errorHandler(error as AxiosError)
    return null
  }
}
const patch = async (url: string, data?: object, config?: AxiosRequestConfig) => {
  const defaultConfig = getDefaultConfig()
  try {
    const result = await Axios.patch(url, data, {
      ...config,
      headers: getHeaders(config, defaultConfig),
      timeout: TIME_OUT,
    })
    return result.data
  } catch (error) {
    errorHandler(error as AxiosError)
    return null
  }
}

const put = async (url: string, data?: object, config?: AxiosRequestConfig) => {
  const defaultConfig = getDefaultConfig()
  try {
    const result = await Axios.put(url, data, {
      ...config,
      headers: getHeaders(config, defaultConfig),
      timeout: TIME_OUT,
    })

    return result.data
  } catch (error) {
    errorHandler(error as AxiosError)
    return null
  }
}

const del = async (url: string, config?: AxiosRequestConfig, axiosInstance?: AxiosInstance, authorization?: string) => {
  const defaultConfig = {
    Authorization: authorization || authorKey || cookie.load(COOKIE_AUTHOR_KEY),
  }
  try {
    const result = await Axios.delete(url, {
      ...config,
      headers: getHeaders(config, defaultConfig),
      timeout: TIME_OUT,
    })
    return result.data
  } catch (error) {
    errorHandler(error as AxiosError)
    return null
  }
}

const get = async (url: string, config?: AxiosRequestConfig) => {
  const defaultConfig = getDefaultConfig()
  try {
    const result = await Axios.get(url, {
      ...config,
      headers: getHeaders(config, defaultConfig),
      timeout: TIME_OUT,
    })

    return result.data
  } catch (error) {
    errorHandler(error as AxiosError)
    return null
  }
}

Axios.defaults.baseURL = config.api.url

const getAuthToken = (): string =>
  Axios.defaults.headers.common.Authorization || authorKey || cookie.load(COOKIE_AUTHOR_KEY)?.replace('Bearer ', '')
const getUserName = (): string => cookie.load('userName')
const getUserId = (): string => cookie.load('userId')
const getUserRoles = (): string[] => {
  const token = getAuthToken()
  const decodedToken = jwt.decode(token, { complete: true })
  return (decodedToken?.payload as JwtPayload)?.resource_access['store-management-portal'].roles || []
}

export const API = {
  post,
  patch,
  get,
  put,
  del,
  setAuthToken,
  getAuthToken,
  getUserName,
  getUserId,
  getUserRoles,
  clearAuthToken,
}

export default API
