import { IProduct } from 'stores/orders/types'
import API from './API'
import { toUrl } from './helper'

export default class ProductAPI {
  static async getProductDetail(productId: number | string): Promise<IProduct> {
    return API.get(`/api/admin/v2/products/${productId}`)
  }

  static searchProduct(name: string): Promise<{ products: IProduct[] }> {
    return API.get(toUrl('/api/admin/v2/products', { name, count: 10 }))
  }
}
