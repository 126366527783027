import * as R from 'ramda'

const filterEmpty = R.filter((v) => !R.isEmpty(v) && !R.isNil(v))
const joinParams = R.join('&')

export const toUrl = (url: string, query: Record<string, unknown> = {}) => {
  const params = joinParams(
    filterEmpty(
      R.map(([key, value]) => {
        const valueArray = Array.isArray(value) ? value : [value]
        return joinParams(R.map((item) => `${key}=${item}`, filterEmpty(valueArray)))
      }, Object.entries(query)),
    ),
  )
  return `${url}?${params}`
}
