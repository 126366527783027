import {
  CHANGE_LAYOUT,
  CHANGE_SIDEBAR_THEME,
  CHANGE_LAYOUT_MODE,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_LAYOUT_POSITION,
  CHANGE_TOPBAR_THEME,
  CHANGE_SIDEBAR_SIZE_TYPE,
  CHANGE_SIDEBAR_VIEW,
  RESET_VALUE,
} from './actionType';

// constants
import {
  layoutTypes,
  leftSidebarTypes,
  layoutModeTypes,
  layoutWidthTypes,
  layoutPositionTypes,
  topbarThemeTypes,
  leftsidbarSizeTypes,
  leftSidebarViewTypes,
  LayoutTypes,
  LayoutModes,
} from '../../VelzonComponents/constants/layout';
import { LayoutActions } from './action';

export type LayoutState = {
  layoutType: LayoutTypes
  leftSidebarType: typeof leftSidebarTypes[keyof typeof leftSidebarTypes]
  layoutModeType: LayoutModes
  layoutWidthType: typeof layoutWidthTypes[keyof typeof layoutWidthTypes]
  layoutPositionType: typeof layoutPositionTypes[keyof typeof layoutPositionTypes]
  topbarThemeType: typeof topbarThemeTypes[keyof typeof topbarThemeTypes]
  leftsidbarSizeType: typeof leftsidbarSizeTypes[keyof typeof leftsidbarSizeTypes]
  leftSidebarViewType: typeof leftSidebarViewTypes[keyof typeof leftSidebarViewTypes]
}

export const initialState: LayoutState = {
  layoutType: layoutTypes.VERTICAL,
  leftSidebarType: leftSidebarTypes.DARK,
  layoutModeType: layoutModeTypes.LIGHTMODE,
  layoutWidthType: layoutWidthTypes.FLUID,
  layoutPositionType: layoutPositionTypes.SCROLLABLE,
  topbarThemeType: topbarThemeTypes.DARK,
  leftsidbarSizeType: leftsidbarSizeTypes.DEFAULT,
  leftSidebarViewType: leftSidebarViewTypes.DEFAULT,
};

const Layout = (state: LayoutState = initialState, action: LayoutActions) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      };

    case CHANGE_LAYOUT_MODE:
      return {
        ...state,
        layoutModeType: action.payload,
      };

    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSidebarType: action.payload,
      };

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidthType: action.payload,
      };

    case CHANGE_LAYOUT_POSITION:
      return {
        ...state,
        layoutPositionType: action.payload,
      };

    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarThemeType: action.payload,
      };

    case CHANGE_SIDEBAR_SIZE_TYPE:
      return {
        ...state,
        leftsidbarSizeType: action.payload,
      };

    case CHANGE_SIDEBAR_VIEW:
      return {
        ...state,
        leftSidebarViewType: action.payload,
      };

    case RESET_VALUE:
      return {
        ...state,
        resetValue: initialState,
      };

    default:
      return state;
  }
};

export default Layout;
