import styled from 'styled-components'
import is from 'styled-is'
import { theme } from 'theme'

export const Container = styled.div<{required: boolean}>`
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-shrink: 0;

  gap: 15px;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;
  min-height: ${theme.dims.product.height};
  padding-right: 10px;
  color: #6b737d;
  font: normal 500 14px/30px Roboto;
  background: #fff;
  border: 1px solid #dce8ef;
  border-radius: 4px;

  &:hover {
    border: 1px solid #afbbc2;
  }
  ${is('required')`
    border: 1px solid ${theme.colors.red}
  `}
`

export const Thumbnail = styled.div<{ thumbnail: string }>`
  width: ${theme.dims.product.height};
  min-width: ${theme.dims.product.height};
  height: ${theme.dims.product.height};
  background: url('${(props: { thumbnail: string }) => props.thumbnail}') #fff no-repeat center center/contain;
`
export const Quantity = styled.input`
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  color: #6b737d;
  font-weight: 500;
  text-align: center;
  background: #fff;
  border: 1px solid #dce8ef;
  border-radius: 5px;
  outline: none;
  &:hover,
  &:active,
  &:focus {
    border-color: #40b4e5;
  }
`
export const Add = styled.button`
  padding: 0 10px;
  color: #6b737d;
  font-weight: bolder;
  font-size: 16px;
  background: white;
  border: none;
  cursor: pointer;
  &:hover {
    color: #1c3e60;
  }
`
export const Sub = styled(Add)``

export const UnitPrice = styled.div<{ price?: boolean }>`
  color: #c5c7cd;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  ${is('price')`
  font-family: Mulish;
  color: #C5C7CD;
  font-weight: normal;
 `}
`
export const Info = styled.div`
  display: flex;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
`
export const Name = styled.div`
  display: -webkit-box;
  flex: 1;
  padding: 0;
  overflow: hidden;
  font-weight: 500;
`

export const QuantityInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const NewQuantityContainer = styled.div`
  display: flex;
`

export const BaseInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: 10px 0;
  line-height: 18px;
`

export const LabelsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`
