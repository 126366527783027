import dayjs from 'dayjs'
import React, { FC, useCallback, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IAppState } from 'stores'
import { OrderStatus, IOrder } from 'stores/orders/types'
import { requestOrders, resetOrders } from 'stores/orders/actions'
import { requestStores } from 'stores/admin/actions'
import { IStore } from 'interface/admin/store'
import { history } from 'browserHistory'
import * as queryString from 'query-string'
import { CrossIcon } from 'components/icons/cross'
import { theme } from 'theme'
import { Container } from 'reactstrap'
import Select, {OnChangeValue} from 'react-select';

import { includes, values } from 'ramda'
import {
  DateText,
  Tag,
  TimeText,
  FilterContainer,
  PageTitle,
  FilterConditions,
  FilterTag,
  RemoveBtn,
} from './style'
import { SearchBar } from './searchBar/index'
import { DateSelector } from './dateSelector/index'
import { ORDER_STATUS_TO_COLOR, ORDER_STATUS_TO_LABEL } from './utils'
import TableContainer from '../common/tableContainer'

export type Nullable<T> = T | null

type StatusOptionType = {label: string, value: OrderStatus}
type StoreOptionType = {label: string, value: IStore | undefined}

const statusesOptions: StatusOptionType[] = Object
  .values(OrderStatus)
  .filter((status) => status !== OrderStatus.UNKNOWN)
  .map((status) => ({label: ORDER_STATUS_TO_LABEL[status], value: status}))

const dateFormat = 'MMM. DD, YY'
const PAGE_SIZE = 15;

type UncheckedSearchParams = {
  searchId: string
  storeId: string
  start: string
  end: string
  status: string
}

type SearchParams = UncheckedSearchParams & {
  status: OrderStatus
}

const parseSearchParams = (query: string): SearchParams => {
  const parsed = queryString.parse(query) as UncheckedSearchParams
  return {
    ...parsed,
    status: includes(parsed.status, values(OrderStatus)) ? parsed.status as OrderStatus : OrderStatus.UNKNOWN,
  }
}

export const OrderList: FC = () => {
  const dispatch = useDispatch()

  const search = parseSearchParams(window.location.search)
  const orders = useSelector((state: IAppState) => state.order.orders || [])
  const stores = useSelector((state: IAppState) => state.admin.stores || [])
  const storesOptions = [...stores.map((store) => ({label: store.storeName, value: store }))]
  const currencySymbol = useSelector((state: IAppState) => state.store.config.currency.symbol)
  const pagination = useSelector((state: IAppState) => state.order.pagination)
  const [dates, setDates] = useState<{ start: Nullable<Date>, end: Nullable<Date> } | null>({
    start: search.start ? new Date(search.start) : null,
    end: search.start ? new Date(search.end) : null,
  })
  const [page, setPage] = useState<number>(0)
  const [searchId, setSearchId] = useState<number | string>()
  const [statusOption, setStatusOption] = useState<StatusOptionType | null>(null)
  const [storeOption, setStoreOption] = useState<StoreOptionType | null>(null)

  const [selector, setSelector] = useState<string>()
  const [pageCount, setPageCount] = useState(1);

  const columns = useMemo(() => [
    {
      Header: 'Date',
      accessor: 'created',
      Cell: (props: { row: { original: IOrder } }) => (
        <div>
          <DateText>
            {dayjs(props.row.original.createdAt).format('MMM D, YYYY')}
          </DateText>
          <TimeText>
            &nbsp;
            {dayjs(props.row.original.createdAt).format('h:mm A')}
          </TimeText>
        </div>
      ),
    },
    {
      Header: 'Receipt ID',
      accessor: 'id',
    },
    {
      Header: 'External Receipt ID',
      accessor: 'externalId',
      Cell: (props: { row: { original: IOrder } }) => (
        <div>
          {props.row.original.externalId || '-'}
        </div>
      ),
    },
    {
      Header: 'Order Value',
      accessor: 'totalPrice',
      Cell: (props: { row: { original: IOrder } }) => {
        const order = props.row.original

        return (
          <div>
            {currencySymbol}
            {order.status === OrderStatus.CONTESTED || order.status === OrderStatus.REVIEWED
              ? order.contest?.reviewedTotalPrice || order.contest?.originalTotalPrice || order.totalPrice
              : order.totalPrice}
          </div>
        )
      },
    },
    {
      Header: 'Subtotal',
      accessor: 'subtotalPrice',
      Cell: (props: { row: { original: IOrder } }) => {
        const order = props.row.original

        return (
          <div>
            {currencySymbol}
            {order.subtotalPrice}
          </div>
        )
      },
    },
    {
      Header: 'Store',
      accessor: 'store',
      Cell: (props: { row: { original: IOrder } }) => {
        const order = props.row.original;
        const store = stores.find((value) => String(value.storeId) === String(order.storeId))

        return <div>
          {store?.storeName}
        </div>
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (props: { row: { original: IOrder } }) => {
        const order = props.row.original;

        return (
          <Tag status={ORDER_STATUS_TO_COLOR[order.status]}>
            {ORDER_STATUS_TO_LABEL[order.status]}
          </Tag>

        )
      },
    },
  ], [stores])

  const handleOnItemClick = useCallback((record: IOrder) => {
    history.push({
      pathname: `/retailer/orders/${record.id}`,
      state: {
        id: record.id,
      },
    })
  }, [])

  const fetchOrders = (pageIndex: number, pageSize: number) => {
    let end = null
    if (dates?.end) {
      end = new Date(dates?.end.toString())
      end.setDate(end.getDate() + 1)
    }
    setPage(pageIndex)
    dispatch(
      requestOrders(
        Number(searchId) || undefined,
        dates?.start || undefined,
        end || undefined,
        pageIndex,
        statusOption?.value,
        storeOption?.value?.storeId,
        pageSize,
      ),
    )
  }

  useEffect(() => {
    if (pagination?.next) {
      setPageCount(page+2)
    } else {
      setPageCount(page+1)
    }
  }, [pagination?.next, page])

  useEffect(() => {
    setPage(0)
    dispatch(resetOrders())
    const params = {
      status: statusOption?.value?.toString() ?? '',
      storeId: stores.length ? storeOption?.value?.storeId?.toString() ?? '' : search.storeId,
      start: dates?.start?.toISOString() ?? '',
      end: dates?.end?.toISOString() ?? '',
    }

    const searchParams = new URLSearchParams(params).toString()

    history.push({
      pathname: '/retailer/orders',
      search: `?${searchParams}`,
    })

    fetchOrders(0, PAGE_SIZE)
  }, [dates, statusOption?.value, searchId, dispatch, storeOption?.value, stores])

  useEffect(() => {
    dispatch(requestStores())
  }, [dispatch])

  useEffect(() => {
    window.addEventListener('click', (e: MouseEvent) => {
      const ids = e.composedPath().map((i) => (i as HTMLInputElement).id)
      if (!ids.includes('statusSelector') && !ids.includes('storeSelector') && !ids.includes('dateSelector')) {
        setSelector('')
      }
    })
  }, [])

  const showActiveFilters = (!!dates?.start && !!dates?.end) || !!statusOption?.value || !!storeOption?.value?.storeId

  useEffect(() => {
    if (search.status !== OrderStatus.UNKNOWN) {
      setStatusOption({ label: ORDER_STATUS_TO_LABEL[search.status], value: search.status})
    }
  }, [search.status])

  useEffect(() => {
    const store = stores.filter((i) => i.storeId === search.storeId)[0]
    if (store) {
      setStoreOption({ label: store.storeName, value: store})
    }
  }, [search.storeId, stores])

  const handleStatusChange = (option: OnChangeValue<StatusOptionType, false>) => {
    setStatusOption(option)
  };

  const handleStoreChange = (option: OnChangeValue<StoreOptionType, false>) => {
    setStoreOption(option)
  };

  return (
    <div className="page-content  min-vh-100 px-4 pt-4" style={{background: '#f3f3f8'}}>
      <Container fluid className="bg-white px-0 py-4">
        <FilterContainer className="px-3">
          <PageTitle>Orders List</PageTitle>
          <SearchBar setSearchId={setSearchId} searchId={searchId} />
          <DateSelector
            setSelected={setDates}
            title="By Date"
            setSelector={setSelector}
            selector={selector}
          />
          <Select
            value={statusOption}
            onChange={handleStatusChange}
            options={statusesOptions}
            placeholder="By Status"
            name="choices-single-default"
            id="idStatus"
            isClearable
            styles={{control: (styles) => ({...styles, minWidth: '118px'})}}
          ></Select>
          <Select
            value={storeOption}
            onChange={handleStoreChange}
            options={storesOptions}
            placeholder="By Store"
            name="choices-single-default"
            id="idStores"
            isClearable
            styles={{control: (styles) => ({...styles, minWidth: '118px'})}}
          ></Select>
        </FilterContainer>
        {showActiveFilters && (
          <FilterConditions>
            {!!dates?.start && !!dates?.end && (
              <FilterTag>
                {`Date: ${dayjs(dates.start).format(dateFormat)} - ${dayjs(dates.end).format(dateFormat)}`}
                <RemoveBtn onClick={() => setDates(null)}>
                  <CrossIcon color={theme.colors.grey[500]} />
                </RemoveBtn>
              </FilterTag>
            )}
            {!!statusOption?.value && (
              <FilterTag>
                {`Status: ${statusOption.label}`}
                <RemoveBtn onClick={() => setStatusOption(null)}>
                  <CrossIcon color={theme.colors.grey[500]} />
                </RemoveBtn>
              </FilterTag>
            )}
            {!!storeOption?.value && (
              <FilterTag>
                {`Store: ${storeOption.value.storeName}`}
                <RemoveBtn onClick={() => {
                  setStoreOption(null)
                }}
                >
                  <CrossIcon color={theme.colors.grey[500]} />
                </RemoveBtn>
              </FilterTag>
            )}
          </FilterConditions>
        )}

        {orders && (
          <TableContainer
            columns={columns}
            data={orders}
            currPage={page}
            customPageSize={PAGE_SIZE}
            pageCount={pageCount}
            fetchData={fetchOrders}
            divClass="table-responsive mb-1"
            tableClass="align-middle table-nowrap"
            theadClass="table-light text-muted"
            onRowClick={handleOnItemClick}
          />
        )}
      </Container>
    </div>
  )
}
