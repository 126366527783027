import styled, { css } from 'styled-components'
import { ifProp, theme } from 'styled-tools'

export const StyledTextarea = styled.textarea`
  width: 100%;
  min-height: 60px;

  padding: 8px 10px;
  color: ${theme('textColors.secondary')};
  font-size: 16px;
  line-height: 24px;
  border: 1px solid ${theme('colors.grey.300')};
  border-radius: 14px;

  outline: none;
  resize: none;

  &::placeholder {
    color: ${theme('textColors.placeholder')};
  }

  &:disabled {
    color: ${theme('colors.grey.500')};
    background-color: ${theme('colors.white')};
  }

  ${ifProp(
    'caution',
    css`
      border: 1px solid ${theme('colors.red')};
    `,
  )}
`
