import React, { FC } from 'react'

interface IProps {
  color?: string
  direction?: 'up' | 'down' | 'right' | 'left'
}

const DIRECTION_TO_DEGREE = {
  up: 90,
  down: 270,
  right: 180,
  left: 0,
}

export const ChevronIcon: FC<IProps> = ({ color, direction }) => (
  <svg
    width={7}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform={`rotate(${DIRECTION_TO_DEGREE[direction ?? 'left']})`}
  >
    <path d="M6 1L1 6l5 5" stroke={color} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
