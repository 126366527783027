import React, { FC } from 'react'

interface IProps {
  color?: string
}

export const CrossIcon: FC<IProps> = ({ color }) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M.516.697c.325-.326.867-.312 1.209.03l9.368 9.368c.342.342.355.883.03
       1.208-.326.326-.867.312-1.209-.03L.546 1.905C.204 1.563.191 1.022.516.697z"
      fill={color}
    />
    <path
      d="M11.122.696c.326.326.338.84.029 1.15l-9.485
       9.486c-.31.31-.825.296-1.15-.03-.326-.325-.339-.84-.029-1.149L9.972.668c.31-.31.825-.297 1.15.028z"
      fill={color}
    />
  </svg>
)
