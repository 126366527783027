import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { IAppState } from 'stores';

const ProfileDropdown = () => {
  const adminName = useSelector((state: IAppState) => state.auth.userName)
  const config = useSelector((state: IAppState) => state.store.config)
  // Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  return (
    <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="header-item topbar-user">
      <DropdownToggle tag="button" type="button" className="btn">
        <span className="d-flex align-items-center">
          <div className="rounded-circle header-profile-user bg-light text-center fs-4 lh-base">
            {adminName?.charAt(0).toUpperCase()}
          </div>
          <span className="text-start ms-2">
            <span className="d-xl-inline-block ms-1 fw-medium user-name-text">
              {adminName}
            </span>
            {/* <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">CTO</span> */}
          </span>
        </span>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">

        <h6 className="dropdown-header">
          {adminName}
        </h6>
        <DropdownItem href={config.jiraHelpdeskLink} target="_blank">
          <i
            className="mdi mdi-link-variant text-muted fs-16 align-middle me-1"
          ></i>
          {' '}
          <span
            className="align-middle" data-key="t-logout"
          >
            Jira Helpdesk
          </span>
        </DropdownItem>
        <DropdownItem href="/logout">
          <i
            className="mdi mdi-logout text-muted fs-16 align-middle me-1"
          ></i>
          {' '}
          <span
            className="align-middle" data-key="t-logout"
          >
            Logout
          </span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;