/* eslint-disable require-yield */
import { put, takeLatest, call } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import {
  requestConfigSuccess, REQUEST_GET_CONFIG, requestCreate, requestUpdate, REQUEST_CREATE, REQUEST_UPDATE,
} from './actions'
import { IConfig } from './types'
import { checkResponseCB } from '../sagaUtils'
import { IResponse } from '../../interface/response/success.response'
import StoreAPI from '../../api/stores'

export function* sagaRequestCreate(action: ReturnType<typeof requestCreate>) {
  const response: IResponse<string> = yield call(
    StoreAPI.create,
    action.payload.storeId,
    action.payload.storeName,
    action.payload.productSource,
  )
  yield checkResponseCB(response, function* () {
    toast.success('Create Store Success!', { delay: 1000 })
  })
}

export function* sagaRequestUpdate(action: ReturnType<typeof requestUpdate>) {
  const response: IResponse<string> = yield call(
    StoreAPI.update,
    action.payload.storeId,
    action.payload.storeName,
    action.payload.productSource,
  )
  yield checkResponseCB(response, function* () {
    toast.success('Update Store Info Success!', { delay: 1000 })
  })
}

export function* sagaRequestConfig() {
  const response: IConfig = yield call(StoreAPI.getConfig)
  yield put(requestConfigSuccess(response))
}

export function* storeSagas() {
  yield takeLatest(REQUEST_CREATE, sagaRequestCreate)
  yield takeLatest(REQUEST_UPDATE, sagaRequestUpdate)
  yield takeLatest(REQUEST_GET_CONFIG, sagaRequestConfig)
}
