import React, { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

// redux
import { useSelector } from 'react-redux'
import { IAppState } from 'stores'

interface NonAuthLayoutProps extends RouteComponentProps {
  children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
}

const NonAuthLayout: React.FC<NonAuthLayoutProps> = ({ children }) => {
  const { layoutModeType } = useSelector((state: IAppState) => ({
    layoutModeType: state.Layout.layoutModeType,
  }))

  useEffect(() => {
    if (layoutModeType === 'dark') {
      document.body.setAttribute('data-layout-mode', 'dark')
    } else {
      document.body.setAttribute('data-layout-mode', 'light')
    }
  }, [layoutModeType])

  return (
    <div>
      {children}
    </div>
  )
}

export default withRouter(NonAuthLayout)
