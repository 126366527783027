export const dims = {
  topBar: {
    height: '64px',
  },
  leftBar: {
    width: '88px',
  },
  searchBar: {
    minWidth: '140px',
  },
  product: {
    height: '56px',
  },
  orderList: {
    additionalMarginBottom: '10px',
  },
}
