import styled from 'styled-components'
import { theme } from 'styled-tools'

export const SelectorContainer = styled.div`
  position: relative;
  width: 111px;
  height: 36px;
  text-align: center;
  border: 1px solid ${theme('colors.grey.300')};
  border-radius: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
`
export const Text = styled.div`
  width: 100%;
  height: 36px;
  color: #2e415b;
  font-size: 14px;
  line-height: 36px;
`
