import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 800px;
  padding: 1.3rem;
`

export const Title = styled.label`
  left: 0;
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  font-style: normal;
  line-height: 26px;

  @media only screen and (min-width: 1200px) {
    position: absolute;
  }
`

export const CameraContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3rem;
  margin-bottom: 1rem;
`

export const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

export const PlayerView = styled.div`
  position: relative;
  display: flex;
  flex-grow: 2;
  max-width: 1000px;
  overflow: hidden;
  background-color: black;
  border-radius: 20px;
  > video {
    width: 100%;
    height: calc(100vh - 400px);
  }
  > canvas {
    position: absolute;
    top: 0rem;
    left: 0rem;
    width: 100%;
    height: 100%;
  }
`

export const ControlBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-arround;
  height: 2rem;
  margin: 0.5rem 0rem;
  padding: 0rem 1rem;
`

export const PlayButton = styled.div`
  width: 2rem;
  height: 2rem;
  > svg {
    cursor: pointer;
    :hover {
      circle {
        stroke-opacity: 0.5;
      }
    }
  }
`

export const SeekBar = styled.div<{ offset?: number }>`
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 1rem;
  margin: 0rem 1rem;
  cursor: pointer;
  > svg {
    position: absolute;
    ${(props: { offset: number }) => `left:${props.offset || 0}px;`}
    transform: translateX(-0.7rem);
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    :hover {
      .bg {
        stroke-opacity: 0.4;
      }
    }
  }
  :before {
    position: absolute;
    left: 0px;
    width: 100%;
    height: 0.1rem;
    background-color: #f4f6f720;
    content: '';
  }
`

export const Timer = styled.label`
  color: white;
  font-weight: 500;
  font-size: 1rem;
`

export const Cases = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
`

export const CaseItemContainer = styled.div<{ selected?: boolean, showBadge?: boolean }>`
  width: 3.5rem;
  height: 2.8rem;
  display: flex;
  align-items: center;
  color: #2e415b;
  margin: 0rem 0.8rem;
  ${(props: { showBadge: boolean }) =>
    props.showBadge
    && `
  > div {
    height: 0px;
    width: 0px;
    :after {
      content: '';
      background-color: #53BCE8;
      width: 0.4rem;
      height: 0.4rem;
      display: block;
      border-radius: 0.2rem;
      transform: translate(-0.2rem, -1.1rem);
    }
  }
  `}
`

export const CaseTime = styled.label<{ selected?: boolean }>`
  position: relative;
  padding: 0.4rem 0.8rem;
  font-weight: 500;
  font-size: 14px;
  background-color: white;
  border: 1px solid white;
  border-radius: 1.4rem;
  cursor: pointer;
  user-select: none;
  ${(props: { selected: boolean }) => (props.selected ? 'opacity:1;' : 'opacity: 0.2;')}
  :hover {
    border: 1px solid white;
  }
`
export const CloseIcon = styled.img`
  position: absolute;
  right: 0;
`
export const CameraPosition = styled.label<{ isSelected: boolean }>`
  margin: 0 10px;
  padding: 8px 15px;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  background: rgba(255, 255, 255, 0.1);
  ${(props: { isSelected: boolean }) => (props.isSelected ? 'opacity: 1;' : 'opacity: 0.2;')}
  border-radius: 50px;
  cursor: pointer;
  :hover {
    border: 1px solid #fff;
  }
`
export const CameraCenter = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  > svg {
    width: 40px;
    height: 20px;
    margin-right: 20px;
  }
`

export const CaseFooter = styled.div`
  display: flex;
  flex-direction: row;
`

export const VideoGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 130px;
  overflow-y: auto;
`

export const Cover = styled.img`
  margin-top: 5px;
  object-fit: cover;
  border-radius: 10px;
`

export const Point = styled.div`
  position: absolute;
  top: -3px;
  right: -3px;
  width: 7px;
  height: 7px;
  background: #53bce8;
  border-radius: 7px;
`
