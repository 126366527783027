import { IConfig } from './types'

export const REQUEST_OPEN = 'store/request_open' as const

export const requestOpen = (id: string) => ({
  type: REQUEST_OPEN,
  payload: {
    id,
  },
})

export const REQUEST_CLOSE = 'store/request_close' as const

export const requestClose = (
  id: string,
  user?: string,
  reason?: string,
  startTs?: number,
  endTs?: number,
  doorStatus?: number,
) => ({
  type: REQUEST_CLOSE,
  payload: {
    id,
    user,
    reason,
    startTs,
    endTs,
    doorStatus,
  },
})

export const REQUEST_DEL = 'store/request_del' as const

export const requestDel = (storeId: string) => ({
  type: REQUEST_DEL,
  payload: {
    storeId,
  },
})

export const REQUEST_CREATE = 'store/request_Create' as const

export const requestCreate = (storeId: string, storeName: string, productSource?: string) => ({
  type: REQUEST_CREATE,
  payload: {
    storeId,
    storeName,
    productSource,
  },
})

export const REQUEST_UPDATE = 'store/request_Update' as const

export const requestUpdate = (storeId: string, storeName?: string, productSource?: string) => ({
  type: REQUEST_UPDATE,
  payload: {
    storeId,
    storeName,
    productSource,
  },
})

export const REQUEST_GET_CONFIG = 'config/request' as const

export const requestConfig = () => ({
  type: REQUEST_GET_CONFIG,
  payload: {},
})

export const REQUEST_GET_CONFIG_SUCCESS = 'config/request_success' as const

export const requestConfigSuccess = (config: IConfig) => ({
  type: REQUEST_GET_CONFIG_SUCCESS,
  payload: {
    config,
  },
})

export type IStoreActions = ReturnType<
  | typeof requestOpen
  | typeof requestClose
  | typeof requestDel
  | typeof requestCreate
  | typeof requestUpdate
  | typeof requestConfig
  | typeof requestConfigSuccess
>
