import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select, { SingleValue } from 'react-select'
import { selectCurrentOrderAudits } from 'stores/audits/selectors'
import { setAuthor } from 'stores/orders/actions'
import { AuditRole } from 'interface/audits/audit'

export enum ReceiptStatus {
  REVISED = 'Revised',
  ORIGINAL = 'Original',
}

export enum ReceiptOption {
  CONTESTED = 'Contested',
  ORIGINAL = 'Original',
  AUDITED = 'Audited',
  INTERNAL_AUDIT = 'Internal Audit',
  EXTERNAL_AUDIT = 'External Audit',
  REVIEWED = 'Reviewed',
}

interface IProps {
  statusValue: ReceiptOption
  isReviewed: boolean
  isContested: boolean
  hasInternalAuditRole: boolean
  setReceiptStatus: (receiptStatus: ReceiptStatus) => void
  setIsInAuditMode: (auditMode: boolean) => void
  setStatusValue: (v: ReceiptOption) => void
}

interface ISelectOption {
  label: ReceiptOption
  value: ReceiptOption
}

interface IAuditOption extends ISelectOption {
  visible: boolean
  isInAuditMode: boolean
}

export const ReceiptStatusSelect: FC<IProps> = ({
  statusValue,
  isReviewed,
  isContested,
  hasInternalAuditRole,
  setReceiptStatus,
  setIsInAuditMode,
  setStatusValue,
}) => {
  const dispatch = useDispatch()
  const orderAudits = useSelector(selectCurrentOrderAudits)
  const retailerAudited = !hasInternalAuditRole && !!orderAudits?.find((p) => p.author === AuditRole.EXTERNAL)
  const internalAudited = hasInternalAuditRole && !!orderAudits?.find((p) => p.author === AuditRole.INTERNAL)
  const externalAudited = hasInternalAuditRole && !!orderAudits?.find((p) => p.author === AuditRole.EXTERNAL)

  const selectOptions: IAuditOption[] = [
    {
      label: ReceiptOption.ORIGINAL,
      value: ReceiptOption.ORIGINAL,
      visible: true,
      isInAuditMode: false,
    },
    {
      label: ReceiptOption.REVIEWED,
      value: ReceiptOption.REVIEWED,
      visible: isReviewed,
      isInAuditMode: false,
    },
    {
      label: ReceiptOption.CONTESTED,
      value: ReceiptOption.CONTESTED,
      visible: isContested,
      isInAuditMode: false,
    },
    {
      label: ReceiptOption.AUDITED,
      value: ReceiptOption.AUDITED,
      visible: retailerAudited,
      isInAuditMode: true,
    },
    {
      label: ReceiptOption.INTERNAL_AUDIT,
      value: ReceiptOption.INTERNAL_AUDIT,
      visible: internalAudited,
      isInAuditMode: true,
    },
    {
      label: ReceiptOption.EXTERNAL_AUDIT,
      value: ReceiptOption.EXTERNAL_AUDIT,
      visible: externalAudited,
      isInAuditMode: true,
    },
  ]

  useEffect(() => {
    if (isContested) {
      setStatusValue(ReceiptOption.CONTESTED)
      setReceiptStatus(ReceiptStatus.REVISED)
      setIsInAuditMode(false)
    }
    if (isReviewed) {
      setStatusValue(ReceiptOption.REVIEWED)
      setReceiptStatus(ReceiptStatus.REVISED)
      setIsInAuditMode(false)
    }
  }, [isContested, isReviewed])

  const onStatusChange = (value: SingleValue<ISelectOption>) => {
    const option = { ...value } as IAuditOption

    setStatusValue(option.value)
    setReceiptStatus(option.value === ReceiptOption.ORIGINAL ? ReceiptStatus.ORIGINAL : ReceiptStatus.REVISED)
    setIsInAuditMode(option.isInAuditMode)
    if (option.value === ReceiptOption.ORIGINAL) {
      dispatch(setAuthor(hasInternalAuditRole ? AuditRole.INTERNAL : AuditRole.EXTERNAL))
    }
    if (option.value === ReceiptOption.INTERNAL_AUDIT) {
      dispatch(setAuthor(AuditRole.INTERNAL))
    } else if (option.value === ReceiptOption.EXTERNAL_AUDIT) {
      dispatch(setAuthor(AuditRole.EXTERNAL))
    }
  }

  return (
    <Select
      value={{ label: statusValue, value: statusValue }}
      options={selectOptions.filter((option) => option.visible)}
      onChange={onStatusChange}
    />
  )
}
