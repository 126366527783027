import { IMenu } from '../../interface/admin/menu'
import { IStore } from '../../interface/admin/store'

export const REQUEST_MENU = 'menu/request' as const

export const requestMenu = () => ({
  type: REQUEST_MENU,
  payload: {},
})

export const REQUEST_MENU_SUCCESS = 'menu/request_success' as const

export const requestMenuSuccess = (menus: IMenu[]) => ({
  type: REQUEST_MENU_SUCCESS,
  payload: {
    menus,
  },
})

export const SELECT_MENU = 'menu/set_selected' as const

export const selectMenu = (path?: string) => ({
  type: SELECT_MENU,
  payload: {
    path,
  },
})

export const SET_OPENED_MENU = 'menu/set_opened' as const

export const setOpenedMenus = (paths: string[]) => ({
  type: SET_OPENED_MENU,
  payload: {
    paths,
  },
})

export const SET_CURRENT_STORE = 'store/set_current' as const

export const setCurrentStore = (storeId?: string) => ({
  type: SET_CURRENT_STORE,
  payload: {
    storeId,
  },
})

export const REQUEST_STORES = 'store/request' as const

export const requestStores = () => ({
  type: REQUEST_STORES,
  payload: {},
})

export const REQUEST_STORES_SUCCESS = 'store/request_success' as const

export const requestStoresSuccess = (stores: IStore[]) => ({
  type: REQUEST_STORES_SUCCESS,
  payload: {
    stores,
  },
})

export type IAdminActions = ReturnType<
  | typeof requestMenu
  | typeof requestMenuSuccess
  | typeof selectMenu
  | typeof setOpenedMenus
  | typeof setCurrentStore
  | typeof requestStores
  | typeof requestStoresSuccess
>
