import React, { FC, useEffect, useState, useCallback, Dispatch, SetStateAction } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { contestOrder, setFinalOrder, requstSubmitContestedOrder, setAuthor } from 'stores/orders/actions'
import { CreateOrderContestPayload, IOrder, IProductSnapshot, OrderStatus } from 'stores/orders/types'
import * as R from 'ramda'
import { EditIcon } from 'components/icons/edit'
import { theme } from 'theme'
import { createAudit, fetchOrderAudits, requestUpdateAudit, fetchAudit } from 'stores/audits/actions'
import {
  selectAuditedProducts,
  selectAuditProductsSum,
  selectAuditedProductsTotalQuantity,
  selectCurrentOrderAudit,
  selectIsCurrentOrderAuditEmpty,
  selectCurrentOrderAudits,
  selectCurrentAuthor,
} from 'stores/audits/selectors'
import { Button } from 'components/button'
import { selectHasUserAuditsRole, selectHasUserContestsRole, selectHasInternalAuditsRole } from 'stores/auth/selectors'
import { config } from 'config'
import {
  selectContestedOrder,
  selectFinalOrderProductsWithContestedFirst,
  selectOriginalTotalPrice,
  selectOriginalTotalQuantity,
  selectProductChangesForAudit,
  selectRefundAmount,
  selectReviewedTotalPrice,
  selectReviewedTotalQuantity,
  selectRevisedProductsSum,
} from 'stores/orders/selectors'
import { IAppState } from 'stores'
import { AuditRole } from 'interface/audits/audit'
import { Product } from '../product'
import { ProductList, DetailTitle, TitleText, Subtitle, HeaderButtons, BottomBox } from './style'
import { AuditBox } from './AuditBox'
import { SearchBar } from './components/SearchBar'
import { AuditBoxReadOnly } from './AuditBoxReadOnly'
import { AuditButton } from './AuditButton'
import { OrderSummary } from './OrderSummary'
import { ReceiptStatusSelect, ReceiptStatus, ReceiptOption } from './components/ReceiptStatusSelect'

interface IProps {
  currentOrder: IOrder
  finalOrder: IOrder
  isInAuditMode: boolean
  setIsInAuditMode: Dispatch<SetStateAction<boolean>>
}

export const Detail: FC<IProps> = ({ finalOrder, currentOrder, isInAuditMode, setIsInAuditMode }) => {
  const dispatch = useDispatch()
  const [customRefund, setCustomRefund] = useState<number | null>()
  const [hasChanged, setHasChanged] = useState(false)
  const [receiptStatus, setReceiptStatus] = useState(ReceiptStatus.REVISED)
  const [issueRequired, setIssueRequired] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [statusValue, setStatusValue] = useState(ReceiptOption.ORIGINAL)

  const contestedOrder = useSelector(selectContestedOrder)
  const finalItems = useSelector(selectFinalOrderProductsWithContestedFirst)
  const currentAudit = useSelector(selectCurrentOrderAudit)
  const hasAuditRole = useSelector(selectHasUserAuditsRole)
  const hasContestRole = useSelector(selectHasUserContestsRole)
  const isAuditEmpty = useSelector(selectIsCurrentOrderAuditEmpty)
  const auditedProducts = useSelector(selectAuditedProducts)
  const currencySymbol = useSelector((state: IAppState) => state.store.config.currency.symbol)
  const originalTotalPrice = useSelector(selectOriginalTotalPrice)
  const revisedProductsSum = useSelector(selectRevisedProductsSum)
  const reviewedTotalPrice = useSelector(selectReviewedTotalPrice)
  const originalRefund = useSelector(selectRefundAmount)
  const currentQuantity = useSelector(selectOriginalTotalQuantity)
  const contestQuantity = useSelector(selectReviewedTotalQuantity)
  const auditProductsSum = useSelector(selectAuditProductsSum)
  const auditTotalQuantity = useSelector(selectAuditedProductsTotalQuantity)
  const productChangesForAudit = useSelector(selectProductChangesForAudit)
  const hasInternalAuditRole = useSelector(selectHasInternalAuditsRole)
  const orderAudits = useSelector(selectCurrentOrderAudits)
  const currentAuthor = useSelector(selectCurrentAuthor)

  const isContested = currentOrder.status === OrderStatus.CONTESTED
  const isPaid = currentOrder.status === OrderStatus.PAID
  const isReviewed = currentOrder.status === OrderStatus.REVIEWED
  const isViewExternal = hasInternalAuditRole && isInAuditMode && currentAuthor === AuditRole.EXTERNAL
  const isInEditMode = isInAuditMode ? isAuditEmpty && !isViewExternal : isContested
  const finalQuantity = isInAuditMode && !isInEditMode ? auditTotalQuantity : contestQuantity
  const refundAmount = R.isNil(customRefund) ? originalRefund : customRefund

  const totalTax = Number(currentOrder?.totalTax ?? 0)

  const { showTax } = config.misc

  useEffect(() => {
    dispatch(setAuthor(hasInternalAuditRole ? AuditRole.INTERNAL : AuditRole.EXTERNAL))
  }, [hasInternalAuditRole])

  useEffect(() => {
    let auditId

    if (currentAuthor === AuditRole.INTERNAL) {
      auditId = orderAudits.find((item) => item.author === AuditRole.INTERNAL)?.id
    } else if (currentAuthor === AuditRole.EXTERNAL) {
      auditId = orderAudits.find((item) => item.author === AuditRole.EXTERNAL)?.id
    }

    auditId && dispatch(fetchAudit(auditId))
  }, [currentAuthor])

  useEffect(() => {
    if (JSON.stringify(currentOrder.products) !== JSON.stringify(finalOrder.products) || !R.isNil(customRefund)) {
      setHasChanged(true)
    } else {
      setHasChanged(false)
    }
  }, [currentOrder.products, finalOrder.products, customRefund])

  useEffect(() => {
    if (hasAuditRole) {
      dispatch(fetchOrderAudits(currentOrder.id))
    }
  }, [dispatch, currentOrder.id, hasAuditRole])

  const getFinalPrice = (originalTotal: number, reviewedTotal: number, refundTotal: number) => {
    const base = Math.max(originalTotal, reviewedTotal)
    return base - refundTotal
  }

  const onSubmit = useCallback(() => {
    if (R.isNil(customRefund)) {
      dispatch(requstSubmitContestedOrder())
      return
    }

    const finalPrice = getFinalPrice(originalTotalPrice, reviewedTotalPrice, refundAmount)
    dispatch(requstSubmitContestedOrder(Number(finalPrice.toFixed(2))))
  }, [dispatch, originalTotalPrice, reviewedTotalPrice, refundAmount, customRefund])

  const onRefundChange = (e: React.FormEvent<HTMLInputElement>) => {
    setCustomRefund(Number(e.currentTarget.value))
  }

  const onContestButton = useCallback(() => {
    const emptyContest: CreateOrderContestPayload = {
      orderId: currentOrder.id,
      message: '-',
      items: [],
    }
    dispatch(contestOrder(emptyContest))
  }, [dispatch, currentOrder.id])

  const onAuditButton = useCallback(() => {
    setIsInAuditMode(true)
    if (R.isNil(currentAudit)) {
      dispatch(createAudit(currentOrder.id))
    }
    setStatusValue(hasInternalAuditRole ? ReceiptOption.INTERNAL_AUDIT : ReceiptOption.AUDITED)
    setReceiptStatus(ReceiptStatus.REVISED)
  }, [dispatch, currentOrder.id, setIsInAuditMode, currentAudit])

  const resetOrderToPreAuditState = () => {
    if (isReviewed) {
      dispatch(setFinalOrder({ ...contestedOrder }))
    } else {
      dispatch(setFinalOrder({ ...currentOrder }))
    }
  }

  const submitAudit = (values: { comment: string }) => {
    const finalProducts = R.map((product) => {
      const originalProduct = R.find(
        (p) => p.productId?.toString() === product.productId?.toString(),
        currentOrder?.products ?? [],
      )
      return {
        ...product,
        originalQuantity: originalProduct ? originalProduct.quantity : 0,
      }
    }, finalOrder.products ?? [])

    const products = R.map(
      (p) => ({
        ...p,
        issueRequired: p.quantity !== p.originalQuantity && !p.categories,
      }),
      finalProducts,
    )

    dispatch(setFinalOrder({ ...finalOrder, products }))

    if (hasInternalAuditRole && R.any((p) => p.issueRequired, products)) {
      setIssueRequired(true)
      return
    }
    setIssueRequired(false)

    if (!values.comment) {
      setErrorMsg('Add comments to submit please')
      return
    }

    if (!R.isNil(currentAudit)) {
      dispatch(
        requestUpdateAudit(finalOrder.id, currentAudit.id, {
          results: productChangesForAudit,
          comment: R.isEmpty(values.comment) ? undefined : values.comment,
        }),
      )
      setIsInAuditMode(true)
    }

    resetOrderToPreAuditState()
  }

  const cancelAudit = () => {
    setIssueRequired(false)
    setIsInAuditMode(false)
    setErrorMsg('')
    resetOrderToPreAuditState()
  }

  const auditedOrFinalProducts = isInAuditMode && !isInEditMode ? auditedProducts : finalItems
  const productsList: IProductSnapshot[] =
    receiptStatus === ReceiptStatus.ORIGINAL ? currentOrder.products ?? [] : auditedOrFinalProducts

  return (
    <div className="card col-5 pt-3 px-4">
      <DetailTitle>
        <TitleText>Order Summary</TitleText>
        <HeaderButtons>
          {hasAuditRole && <AuditButton isAuditEmpty={isAuditEmpty} isInAuditMode={isInAuditMode} onAudit={onAuditButton} />}
          {hasContestRole && isPaid && (
            <Button
              disabled={isInEditMode || isInAuditMode}
              onClick={onContestButton}
              kind="primary"
              iconLeft={<EditIcon fillColor={theme.colors.white} />}
            >
              Contest
            </Button>
          )}
        </HeaderButtons>
      </DetailTitle>
      <Subtitle>
        <ReceiptStatusSelect
          statusValue={statusValue}
          isReviewed={isReviewed}
          isContested={isContested}
          hasInternalAuditRole={hasInternalAuditRole}
          setIsInAuditMode={setIsInAuditMode}
          setReceiptStatus={setReceiptStatus}
          setStatusValue={setStatusValue}
        />
        {isInEditMode && <SearchBar currencySymbol={currencySymbol} finalOrder={finalOrder} />}
      </Subtitle>
      <ProductList>
        {productsList?.map((product: IProductSnapshot) => (
          <Product
            currentOrder={currentOrder}
            isInEditMode={receiptStatus === ReceiptStatus.ORIGINAL ? false : isInEditMode}
            key={product.productId}
            product={product}
            finalOrder={finalOrder}
            currencySymbol={currencySymbol}
            cancelTips={() => setIssueRequired(false)}
            isInAuditMode={isInAuditMode}
          />
        ))}
      </ProductList>
      <BottomBox>
        <OrderSummary
          currencySymbol={currencySymbol}
          currentQuantity={currentQuantity}
          finalQuantity={finalQuantity}
          totalTax={totalTax}
          originalTotalPrice={originalTotalPrice}
          reviewedTotalPrice={reviewedTotalPrice}
          revisedProductsSum={revisedProductsSum}
          auditProductsSum={auditProductsSum}
          refundAmount={refundAmount}
          onRefundChange={onRefundChange}
          hasChanged={hasChanged}
          onCancel={() => dispatch(setFinalOrder({ ...currentOrder }))}
          onSubmit={onSubmit}
          orderStatus={currentOrder.status}
          receiptStatus={receiptStatus}
          issueRequired={issueRequired}
          isInAuditMode={isInAuditMode}
          showTax={!!showTax}
          isAuditEmpty={isAuditEmpty}
        />
        {isInAuditMode && isAuditEmpty && isInEditMode && (
          <AuditBox onCancel={cancelAudit} onSubmit={submitAudit} errorMsg={errorMsg} setErrorMsg={setErrorMsg} />
        )}
        {!R.isNil(currentAudit) && isInAuditMode && !isInEditMode && <AuditBoxReadOnly comment={currentAudit.comment} />}
      </BottomBox>
    </div>
  )
}
