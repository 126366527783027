import styled from 'styled-components'
import { theme } from 'theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const ContestView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (min-width: 1200px) {
    flex-direction: row;
  }
`

export const ProductList = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  gap: 12px;
  padding-right: 20px;
`
export const FeedbackView = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;

  @media only screen and (min-width: 1200px) {
    margin-top: 0px;
    padding-left: 20px;
  }
`

export const Divider = styled.div`
  width: 1px;
  background: #dce8ef;
`
export const Message = styled.div`
  color: ${theme.colors.grey[600]};
  font-weight: ${theme.fonts.weights.light};
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.2px;
`
export const Title = styled.div`
  color: ${theme.colors.deepBlue[200]};
  font-weight: ${theme.fonts.weights.bold};
  font-size: 18px;
  line-height: 21px;
`

export const ProductPlaceholder = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 16px;
`

export const PlaceholderMessage = styled.span`
  color: ${theme.colors.grey[600]};
  font-weight: ${theme.fonts.weights.bold};
  font-size: 16px;
`
