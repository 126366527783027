import { CameraIcon } from 'components/icons/cameraIcon'
import React, { FC } from 'react'
import CloseSvg from 'assets/icons/ic-close.svg'
import { IVideo } from 'stores/receipt/types'
import {
  CameraCenter, CameraContainer, CameraPosition, CloseIcon, Title,
} from './styled'

export enum CameraType {
  Left = 0,
  Top = 1,
  Right = 2,
}

interface IProps {
  camera?: CameraType
  onSelectCamera: (camera: CameraType) => void
  onClose: () => void
  videos: IVideo[]
}

export const CameraBar: FC<IProps> = ({
  onSelectCamera, camera, onClose, videos,
}) => (
  <CameraContainer>
    <Title>Cases Video</Title>
    <CameraCenter>
      <CameraIcon fillColor="white" />
      {videos.map((video, i) => (
        <CameraPosition
          key={i}
          isSelected={camera === i}
          onClick={() => {
            onSelectCamera(i)
          }}
        >
          {i + 1}
        </CameraPosition>
      ))}
    </CameraCenter>
    <CloseIcon src={CloseSvg} onClick={onClose} />
  </CameraContainer>
)

CameraBar.defaultProps = {
  camera: CameraType.Left,
}
