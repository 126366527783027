import React, { FC, useMemo } from 'react'
import dayjs from 'dayjs'
import { ICase } from 'stores/receipt/types'
import { SubTitle } from 'components/orderDetail/style'
import { CameraIcon } from 'components/icons/cameraIcon'
import { useSelector } from 'react-redux';
import TableContainer from 'components/common/tableContainer'
import { AuditLinkCell } from '../general/style'
import { ActionCount } from './style'
import { Container } from '../contestProduct/style'
import Link from '../../../../assets/icons/link.svg'
import { config } from '../../../../config';
import { selectHasInternalAuditsRole } from '../../../../stores/auth/selectors'

interface IProps {
  data?: ICase[]
  onSelected: (caseData: ICase) => void
  sessionId: string
}

export const Cases: FC<IProps> = ({ data, onSelected, sessionId }) => {
  const hasInternalAuditsRole = useSelector(selectHasInternalAuditsRole)

  const columns = useMemo(() => [
    {
      Header: 'Case ID',
      accessor: 'caseId',
    },
    {
      Header: 'Time',
      accessor: 'createTime',
      Cell: (props: { row: { original: ICase } }) => (
        <div>
          {dayjs(Number(props.row.original.createTime) * 1000).format('h:mm:ss A')}
        </div>
      ),
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (props: { row: { original: ICase } }) =>
        props.row.original.operationResult.carts.map((cart) => cart.items.map((product) => (
          <span key={product.name} className="gx-4">
            <ActionCount>
              {product?.increment > 0 ? `+${product?.increment}` : `${product?.increment}`}
            </ActionCount>
            {product.name}
          </span>
        ))),

    },
    {
      Header: '',
      accessor: 'link',
      Cell: (props: { row: { original: ICase } }) => hasInternalAuditsRole && (
        <AuditLinkCell>
          <a
            href={`${config.hat.url}/#/${sessionId}/cases/${props.row.original.caseId} `}
            onClick={(e) => e.stopPropagation()}
          >
            <img src={Link} alt="Go to HAT" />
          </a>
        </AuditLinkCell>
      ),
    },
  ], [])

  return (
    <Container>
      <SubTitle>
        Cases
        <CameraIcon fillColor="rgba(46, 65, 91, 0.5)" />
      </SubTitle>
      <TableContainer
        columns={columns}
        data={data || []}
        pagination={null}
        onRowClick={onSelected}
        divClass="table-responsive mb-1"
        tableClass="align-middle table-nowrap"
        theadClass="table-light text-muted"
      />
    </Container>
  );
}
