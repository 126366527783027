import { createSelector } from 'reselect'
import * as R from 'ramda'
import { IAppState } from '../index'
import { USER_ROLES } from './types'

export const selectAuthState = (store: IAppState) => store.auth

export const selectUserRoles = createSelector(selectAuthState, (authState) => authState.roles ?? [])

export const selectHasUserDebugRole = createSelector(selectUserRoles, (roles) => R.includes(USER_ROLES.DEBUG, roles))

export const selectHasUserAuditsRole = createSelector(selectUserRoles, (roles) => R.includes(USER_ROLES.AUDITS, roles))

export const selectHasUserContestsRole = createSelector(selectUserRoles, (roles) => R.includes(USER_ROLES.CONTESTS, roles))

export const selectHasInternalAuditsRole = createSelector(selectUserRoles,
  (roles) => R.includes(USER_ROLES.INTERNAL_AUDITS, roles))