import { createSelector } from 'reselect'
import * as R from 'ramda'
import { sum } from 'lodash'
import { IGroupedAudits, AuditRole } from '../../interface/audits/audit'
import { IAppState } from '../index'
import { IProductSnapshot } from '../orders/types'
import { selectHasInternalAuditsRole } from '../auth/selectors'
import { selectFinalOrder } from '../orders/selectors'

const isEmpty = R.anyPass([R.isNil, R.isEmpty])

export const selectAuditsState = (store: IAppState): IGroupedAudits => store.audits

export const selectCurrentOrderState = (store: IAppState) => store.order.currentOrder

export const selectCurrentAuthor = (store: IAppState) => store.order.author

export const selectCurrentOrderId = createSelector(selectCurrentOrderState, (currentOrder) => R.toString(currentOrder?.id))

export const selectCurrentOrderAudits = createSelector(
  selectAuditsState,
  selectCurrentOrderId,
  (audits, orderId) => R.prop(orderId, audits) ?? [],
)

export const selectCurrentOrderAudit = createSelector(selectCurrentOrderAudits, selectCurrentAuthor, (audits, author) =>
  audits.find((item) => item.author === author),
)

export const selectCurrentOrderAuditDetail = createSelector(
  selectAuditsState,
  selectCurrentOrderId,
  selectCurrentAuthor,
  (audits, orderId, author) => {
    const auditId = audits[orderId]?.find((item) => item.author === author)?.id

    return auditId ? audits[auditId] : null
  },
)

export const selectAuditedProducts = createSelector(selectFinalOrder, selectCurrentOrderAuditDetail, (finalOrder, audit) => {
  const originalProducts = (finalOrder?.products ?? []).sort(
    (product1, product2) => Number(product1.productId!) - Number(product2.productId!),
  )
  const auditProducts = audit ? audit[0]?.results : []

  return R.reduce(
    (finalProducts, { product, quantity, categories }): IProductSnapshot[] => {
      const targetProductIndex = R.findIndex(
        (finalProduct) => String(finalProduct.productId) === String(product.id),
        finalProducts,
      )

      if (targetProductIndex >= 0) {
        const auditedProduct = R.mergeRight(finalProducts[targetProductIndex], {
          ...product,
          productId: R.toString(product.id),
          quantity,
          price: Number(product.price),
          categories,
        })

        return R.set(R.lensIndex(targetProductIndex), auditedProduct, finalProducts) as IProductSnapshot[]
      }
      return R.append(
        {
          ...product,
          id: R.toString(product.id),
          productId: R.toString(product.id),
          quantity,
          price: product.price,
          categories,
        },
        finalProducts,
      )
    },
    originalProducts,
    auditProducts,
  )
})

export const selectAuditProductsSum = createSelector(selectAuditedProducts, (products) =>
  sum(products.map(({ quantity, price }) => Number(quantity) * Number(price))),
)

export const selectAuditedProductsTotalQuantity = createSelector(selectAuditedProducts, (products) =>
  sum(products.map(({ quantity }) => Number(quantity))),
)

export const selectIsCurrentOrderAuditEmpty = createSelector(
  selectCurrentOrderAudit,
  selectHasInternalAuditsRole,
  selectCurrentOrderAudits,
  (audit, hasInternalRole, orderAudits) => {
    const internalAudit = orderAudits.find((item) => item.author === AuditRole.INTERNAL)
    const aifiAudit = hasInternalRole && (!internalAudit || R.all(isEmpty, [internalAudit.comment, internalAudit.results]))
    const retailerAudit = !hasInternalRole && R.all(isEmpty, [audit?.comment, audit?.results])

    return aifiAudit || retailerAudit
  },
)
