import styled from 'styled-components'
import { Form } from 'formik'

export const FlexForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > textarea {
    min-height: 60px;
    margin-top: 12px;
    border-radius: 4px;
  }
`
